import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";

const baseUrl = process.env.REACT_APP_BASE_URL;

const fetchStatusList = (): object => ({
	url: baseUrl + "/api/Fetch/v1/ActionTaken",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const useCommandStatusList = () => {
  return useCustomQuery([queryKeys.commandStatusList], fetchStatusList(), {
	select: (data) => {
		return data.actionTakenValueList;
	}
  })
}

export default useCommandStatusList