import React, { useCallback, useEffect, useState } from "react";
import BoxWrapper from "../../../QuarantinePortal/BoxWrapper";
import useDeviceSearch from "../useDeviceSearch";
import { set, useForm } from "react-hook-form";
import InputField from "../InputField";
import { useUserInfo } from "../../../../store/auth/UserInfoContext";
import DDSSearch from "../../../Dashboardv3/Search/DDSSearch";
import { useLoadingMessage } from "../useLoadingMessage";
import { useSearch } from "../../../Dashboardv3/Search/useSearch";
import UploadFileBox from "./UploadFileBox";
import { DDSButton, DDSModal } from "@dds/react";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import { useAlert } from "react-alert";
import InputTextArea from "../InputTextArea";
import useBlockDeviceAPI, { BlockDeviceBodyType } from "../useBlockDeviceAPI";
import DeviceDetailsTable from "./DeviceDetailsTable";
import useUnblockDeviceAPI from "../useUnblockDeviceAPI";
import { Select } from "@dds/components";

export enum Category {
	block,
	unblock,
}

const keyHeadingMap = {
	hostname: "Hostname",
	machineID: "Machine ID",
	deviceObjectID: "Device Object ID",
	serviceTag: "Service Tag",
	empName: "Employee Name",
	empEmail: "Employee Email",

	reasonOfBlocking: "Reason of blocking",
	notes: "Notes",

	lastIpAddress: "Last IP Address",
	lastSeen: "Last Seen",
	healthStatus: "Health Status",
	latestActionStatus: "Action Status",
	latestAction: "Action"

};

/**
 * This component is used to block or unblock a device.
 * @param category - The category of the action to be performed. Either block or unblock.
 * @returns 
 */

/**
 * 
 * @param param0 - The category of the action to be performed. Either block or unblock.
 * @returns 
 * 
 *  Total scenarios covered:
 *
 *  UNBLOCK:
 *	Unisolate, Restrict - Show Restrict -Partial Unisolate
 *	Isolate - show Unisolate
 *	Unisolate - Dont give access to block or unblock
 *
 *	BLOCKED:
 *	Unisolate, Restrict - Show Isolate
 *	Isolate - Dont give access to block or unblock
 *	Unisolate - Show both(Isolate, Restrict)
 *	By default Action =  null(Isolate, Restrict)
 *  RENAMED THE BELOW SCENARIOS
 *  Isolate --> Full_Isolated
 *  Restrict --> Partial_Isolated
 *  Unisolate ---> Full_Unisolate
 *  Unrestrict ---> Partial_Unisolated
 */

const BlockUnblockPageHelper: React.FC<{
	category: Category;
}> = ({ category }) => {

	const [mode, setMode] = useState("search");
	const { searchText, setSearchText, searchRef } = useSearch(undefined, mode);
	const {
		isError,
		isFetching,
		data: userDetails,
		refetch,
	} = useDeviceSearch(searchText);
	const { register, handleSubmit, formState, setValue } = useForm();
	const { errors } = formState;

	// type BodyType = category == Category.block ? lockDeviceBodyType
	const [apiBody, setApiBody] = useState<BlockDeviceBodyType>();
	const [blockType, setBlockType] = useState("");
	//category === Category.block ? "Isolate" : "Restrict");


	const alert = useAlert();



	const onSubmit = data => {
		openModal();
		setApiBody({
			hostName: userDetails!.hostname,
			deviceID: (blockType === "Full_Isolate" || blockType === "Full_Unisolate") ? userDetails!.machineID : userDetails!.deviceObjectID,
			serviceTag: userDetails!.serviceTag,
			empName: userDetails!.empName,
			empEmail: userDetails!.empEmail,

			reasonOfBlocking: data.reasonOfBlocking,
			notes: data.notes,
			type: blockType,
		})

		console.log(data);
	};

	const [userClickedOnSearch, setUserClickedOnSearch] = useState(false);

	const loadingMessage = useLoadingMessage(isFetching);
	const [isOpen, setIsOpen] = useState(false);
	const openModal = useCallback(() => setIsOpen(true), []);
	const closeModal = () => {
		setApiBody(undefined);
		setIsOpen(false);
	};

	const resetForm = () => {
		setUserClickedOnSearch(false);
		setSearchText("");
		setMode("search");
	};
	const submitForm = () => {
		handleSubmit(onSubmit)();
	};

	useEffect(() => {
		setValue("blockingRequestedBy", user.getUserInfo().email);
	}, []);

	useEffect(() => {
		if (category === Category.block) {
			if (userDetails?.latestAction === "Full_Unisolate, Partial_Isolate") {
				setBlockType("Full_Isolate");
			} else if (userDetails?.latestAction === "Full_Unisolate") {
				setBlockType("Full_Isolate");
			} else if (userDetails?.latestAction === "null" || userDetails?.latestAction === "" || userDetails?.latestAction === null || userDetails?.latestAction === undefined) {
				setBlockType("Full_Isolate");
			}
		} else {
			if (userDetails?.latestAction === "Full_Unisolate, Partial_Isolate") {
				setBlockType("Partial_Unisolate");
			} else if (userDetails?.latestAction === "Full_Isolate") {
				setBlockType("Full_Unisolate");
			} else if (userDetails?.latestAction === "Partial_Isolate") {
				setBlockType("Partial_Unisolate");
			}
		}
	}, [userDetails]);

	const handleSearchSubmit = (searchText) => {
		console.log("search:" + searchText)
		if (searchText.length == 0) alert.show("Please enter a search query!");
		else {
			setUserClickedOnSearch(true);
			refetch();
		}
	};

	useEffect(() => {
		searchRef.current?.addEventListener("ddsSearchSubmitEvent", (e: any) => {
			setSearchText(e.detail.query);
			handleSearchSubmit(e.detail.query);
		});
	}, [mode]);

	const user = useUserInfo();

	const prefillForm = () => {
		if (userDetails) {
			setValue("hostname", userDetails.hostname);
			setValue("deviceID", userDetails.deviceObjectID);
			setValue("serviceTag", userDetails.serviceTag);
			setValue("employeeName", userDetails.empName);
			setValue("employeeEmail", userDetails.empEmail);
		}
		setMode("fill");
	};

	const startManualMode = () => {
		setMode("fill");
	};

	useEffect(() => {
		console.log(isFetching);
	}, [isFetching]);


	const handleSuccess = () => {
		alert.show("Action Performed successfully!");
		closeModal();
		//below the two lines to reset the form
		setUserClickedOnSearch(false);
		setSearchText("");

		setMode("search");
	};

	const handleError = (error) => {
		// alert.error("Error performing action!");
		console.log(error);
	}
	const { mutate: blockApiCall, isLoading: isPerformingBlock, isError: isBlockError } = useBlockDeviceAPI(handleSuccess, handleError);
	const { mutate: unblockApiCall, isLoading: isPerformingUnBlock, isError: isUnblockError } = useUnblockDeviceAPI(handleSuccess, handleError);


	const performAction = () => {
		//console.log("apiBody=======?", apiBody);
		if (category === Category.block) {
			blockApiCall(apiBody);
		} else {
			unblockApiCall(apiBody);
		}

	};
	useEffect(() => {
		const element = document.getElementById("select-564343528");
		new Select(element);
	}, [mode])

	const reasonList = [
		"CORP domain",
		"EOL-Non Refreshed System - SS",
		"In stock - SS",
		"Loaner PC - SS",
		"Machine replaced-Refreshed PC - SS",
		"Missing - Lost/Stolen",
		"OS Not Upgraded",
		"Retired Machine - SS",
		"Security non-compliance (lack of last security updates)",
		"Terminated User - Leaver - SS",
		"Unapproved software installed",
		"Unused device - SS",
	];

	const reasonListUnblock = [
		"Refresh Request Submitted - SS",
		"Return extension granted - SS",
		"SNOW records updated - SS",
		"The Machine is being used again - SS",
		"The Machine is now security compliant",
		"The OS was upgraded",
		"The Unapproved software was removed",
	];

	const selectedBlockTypeHandler = (e) => {
		setBlockType(e.target.value);
	}

	const renderSelectDeviceEnabled = () => (
		<div>
			<p>
				<strong>
					Do you want to select this device?
				</strong>
			</p>
			<button
				className="dds__button dds__button--primary dds__button--sm dds__ml-2"
				onClick={prefillForm}
			>
				Yes
			</button>
		</div>
	)

	const renderSelectDevice = (userDetails) => {
		//console.log(JSON.stringify(userDetails))
		if (category === Category.block) {
			if (userDetails.latestAction === "Full_Unisolate, Partial_Isolate") {
				return renderSelectDeviceEnabled();
			} else if (userDetails.latestAction === "Full_Unisolate") {
				return renderSelectDeviceEnabled();
			} else if (userDetails.latestAction === "" || userDetails.latestAction === null || userDetails.latestAction === "null" || userDetails.latestAction === undefined) {
				return renderSelectDeviceEnabled();
			}
		} else {
			if (userDetails.latestAction === "Full_Unisolate, Partial_Isolate") {
				return renderSelectDeviceEnabled();
			} else if (userDetails.latestAction === "Full_Isolate") {
				return renderSelectDeviceEnabled();
			} else if (userDetails.latestAction === "Partial_Isolate") {
				return renderSelectDeviceEnabled();
			}
		}
	}

	return (
		<>
			<div
				className="dds__d-flex dds__flex-wrap dds__align-items-start"
				style={{
					gap: "1rem",
				}}
			>
				<BoxWrapper style={{ flex: 4, minWidth: 600 }}>
					<h4 className="dds__h4">
						{category === Category.block ? "Block Device" : "Unblock Device"}
					</h4>
					{mode === "search" && (
						<div>
							<label className="dds__label">Search with Hostname/Service Tag</label>
							<DDSSearch
								style={{
									borderRadius: "14px",
									height: "48px",
								}}
								disabled={userClickedOnSearch ? true : false}
								searchRef={searchRef}
								searchText={searchText}
								setSearchText={setSearchText}
								placeholder="W118W10SQ4"
							/>

							{isFetching && (
								<LoadingSpinner label={loadingMessage} size="md" />
							)}
							{!isFetching &&
								userClickedOnSearch &&
								(userDetails?.hostname == null || userDetails?.deviceObjectID == "" ? (
									<p>
										No result found.
										Please make sure you have entered the correct Hostname.
										<br />
										Example: W118W10SQ4, W118W10SQ5, W108W10SQ6
									</p>
								) : (
									<div
										className=" dds__border dds__p-4"
										style={{
											borderRadius: 24,
										}}
									>
										<h5>Device Found</h5>
										<DeviceDetailsTable data={userDetails} keyHeadingMap={keyHeadingMap} />
										{renderSelectDevice(userDetails)}

										{/* <button
											className="dds__button dds__button--secondary dds__button--sm dds__ml-2"
											onClick={startManualMode}
										>
											Enter Manually
										</button> */}
									</div>
								))}
						</div>
					)}
					{mode === "fill" && (
						<div className="dds__container">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="dds__row dds__mb-3">
									<div className="dds__col--2 dds__col--sm-3">
										<InputField
											register={register}
											name="hostname"
											label="HostName"
											placeholder="HostName"
											errors={errors}
											salt={category.toString()}
										/>
									</div>
								</div>
								<div className="dds__row dds__mb-3">
									<div className="dds__col--2 dds__col--sm-3">
										<InputField
											register={register}
											name="deviceID"
											label="Device ID"
											placeholder="Device ID"
											errors={errors}
											salt={category.toString()}
										/>
									</div>
									<div className="dds__col--2 dds__col--sm-3">
										<InputField
											register={register}
											name="serviceTag"
											label="Service Tag"
											placeholder="Service Tag"
											errors={errors}
											salt={category.toString()}
										/>
									</div>
								</div>
								<div className="dds__row dds__mb-3">
									<div className="dds__col--2 dds__col--sm-3">
										<InputField
											register={register}
											name="employeeName"
											label="Employee Name"
											placeholder="Employee Name"
											errors={errors}
											salt={category.toString()}
										/>
									</div>
									<div className="dds__col--2 dds__col--sm-3">
										<InputField
											register={register}
											name="employeeEmail"
											label="Employee Email"
											placeholder="Employee Email"
											errors={errors}
											salt={category.toString()}
										/>
									</div>
								</div>
								<div className="dds__row dds__mb-3">
									<div className="dds__col--2 ">
										<InputField
											register={register}
											name="blockingRequestedBy"
											label={category === Category.block ? "Blocking Requested by" : "Unblocking Requested by"}
											placeholder="Blocking Requested by"
											errors={errors}
											salt={category.toString()}
										/>
									</div>
								</div>

								{/* Radio Button for Soft or Hard Block */}
								<div className="dds__row dds__mb-3" style={{ marginLeft: "0px" }}>
									<fieldset aria-required="true" onChange={selectedBlockTypeHandler} className="dds__fieldset dds__radio-button-group" role="radiogroup">
										<legend>{category === Category.block ? "Select type of blocking" : "Select type of unblocking"}</legend>
										{(category === Category.block) ?
											<div style={{ display: "flex", gap: "6rem", }}>
												{(userDetails?.latestAction == "Full_Unisolate, Partial_Isolate" || userDetails?.latestAction == "Full_Unisolate" || userDetails?.latestAction == "" || userDetails?.latestAction === null || userDetails?.latestAction === "null" || userDetails?.latestAction === undefined) && <div className="dds__radio-button">
													<input
														className="dds__radio-button__input"
														type="radio"
														name="name-186378323"
														id="radio-button-control-973825587"
														defaultChecked
														value={userDetails?.latestAction == "Full_Unisolate, Partial_Isolate" ? "Full_Isolate" : "Full_Isolate"}
													/>
													<label className="dds__radio-button__label" id="radio-button-label-973825587" htmlFor="radio-button-control-973825587">{userDetails?.latestAction == "Full_Unisolate, Partial_Isolate" ? "Full_Isolate" : "Full_Isolate"}</label>
												</div>}
												{(userDetails?.latestAction == "Full_Unisolate" || userDetails?.latestAction == "" || userDetails?.latestAction === null || userDetails?.latestAction === "null" || userDetails?.latestAction === undefined) && <div className="dds__radio-button">
													<input
														className="dds__radio-button__input"
														type="radio"
														name="name-186378323"
														id="radio-button-control-365872216"
														value="Partial_Isolate" />
													<label className="dds__radio-button__label" id="radio-button-label-365872216" htmlFor="radio-button-control-365872216">Partial_Isolate</label>
												</div>}
											</div>
											: <div style={{ display: "flex", gap: "6rem", }}>
												{(userDetails?.latestAction == "Full_Unisolate, Partial_Isolate" || userDetails?.latestAction == "Full_Isolate" || userDetails?.latestAction == "Partial_Isolate") && <div className="dds__radio-button">
													<input
														className="dds__radio-button__input"
														type="radio"
														name="name-186378323"
														id="radio-button-control-973825587"
														defaultChecked
														value={userDetails?.latestAction == "Full_Unisolate, Partial_Isolate" ? "Partial_Unisolate" :
															userDetails?.latestAction == "Partial_Isolate" ? "Partial_Unisolate" :
																"Full_Unisolate"}
													/>
													<label className="dds__radio-button__label" id="radio-button-label-973825587" htmlFor="radio-button-control-973825587">
														{userDetails?.latestAction == "Full_Unisolate, Partial_Isolate" ? "Partial_Unisolate" :
															userDetails?.latestAction == "Partial_Isolate" ? "Partial_Unisolate" :
																"Full_Unisolate"}
													</label>
												</div>}
											</div>}
									</fieldset>
								</div>
								<div className="dds__row dds__mb-3">
									<div className="dds__col--2 ">
										<div className="dds__select" data-dds="select" id="select-564343528">
											<label id="select-label-564343528" htmlFor="select-control-564343528" className="dds__label dds__label--required">
												{category === Category.block ? "Reason of blocking" : "Reason of Unblocking"}
											</label>
											<div className="dds__select__wrapper">
												<select
													{...register("reasonOfBlocking", {
														required: true,
														validate: value => value !== "" || "This field is required"
													})}
													id="select-control-564343528"
													aria-label="Select label"
													className="dds__select__field"
													aria-describedby="select-helper-564343528"
													required={true}
													placeholder="Reason of blocking"
													style={{
														borderRadius: "14px",
													}}
												>
													<option value={""} className=" dds__select__option--placeholder" selected>Select a Reason</option>
													{category === Category.block ?
														reasonList.map((reason) => (
															<option key={reason} value={reason}>{reason}</option>
														)) :
														reasonListUnblock.map((reason) => (
															<option key={reason} value={reason}>{reason}</option>
														))}
												</select>
												<small className="dds__invalid-feedback" style={{
													display: errors["reasonOfBlocking"] ? 'block' : 'none'
												}}>This field is required</small>
											</div>
										</div>

										{/* <InputTextArea
											register={register}
											name="reasonOfBlocking"
											label={category === Category.block ? "Reason of blocking" : "Reason of unblocking"}
											placeholder="Reason of blocking"
											errors={errors}
											salt={category.toString()}
										/> */}
									</div>
								</div>
								<div className="dds__row">
									<div className="dds__col--2 ">
										<InputTextArea
											register={register}
											name="notes"
											label="Notes"
											placeholder="Notes"
											errors={errors}
											salt={category.toString()}
										/>
									</div>
								</div>
							</form >
						</div >
					)}

					<div className="dds__d-flex dds__justify-content-end dds__mt-5">
						<button
							type="button"
							className="dds__button dds__button--secondary"
							onClick={resetForm}
						>
							Reset
						</button>
						{mode === "fill" ? (
							<button
								type="submit"
								className="dds__button dds__button--primary dds__ml-2"
								onClick={submitForm}
								aria-label="Submit Block Device form"
							>
								Submit
							</button>
						) : (
							<button
								type="submit"
								className="dds__button dds__button--primary dds__ml-2"
								onClick={() => searchRef.current?.dispatchEvent(new CustomEvent("ddsSearchSubmitEvent", { detail: { query: searchText } }))}
								aria-label="Search with Hostname, Host ID or Service Tag"
							>
								Search
							</button>
						)}
					</div>
				</BoxWrapper >
				<UploadFileBox category={category} />
			</div >
			<DDSModal
				size={"md"}
				onClose={closeModal}
				open={isOpen}
				title={category === Category.block ? "Block Device" : "Unblock Device"}
				footer={
					<div>
						<DDSButton
							kind="secondary"
							onClick={closeModal}
							className="dds__mr-2"
						>
							Cancel
						</DDSButton>
						<DDSButton onClick={performAction}>Yes</DDSButton>
					</div>
				}
			>
				{!(isPerformingBlock || isPerformingUnBlock) && (
					<>
						<h5>
							Are you sure you want to{" "}
							{category === Category.block ? "Block" : "Unblock"} this device?
						</h5>
						<DeviceDetailsTable data={{
							hostname: apiBody?.hostName,
							reasonOfBlocking: apiBody?.reasonOfBlocking,
							notes: apiBody?.notes,
						}} keyHeadingMap={keyHeadingMap} />
						{isBlockError && <p style={{ color: "red" }}>Error occurred while blocking device</p>}
						{isUnblockError && <p style={{ color: "red" }}>Error occurred while unblocking device</p>}
					</>
				)}
				{(isPerformingBlock || isPerformingUnBlock) && (
					<LoadingSpinner label="Processing..."></LoadingSpinner>
				)}
			</DDSModal>
		</>
	);
};
export default BlockUnblockPageHelper;
