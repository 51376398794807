import { useEffect, useState } from "react";
import { orgAssetsConfig, orgAssetsCountConfig } from "../API";
import Card from "./Card";
import { PageTitle, FilterCardWrapper } from "./styles";
import AllAssetIco from "../../../Assets/Asset_ico.svg";
import BusiFundedIco from "../../../Assets/Business_fund_ico.svg";
import ITFundIco from "../../../Assets/IT_funded_ico.svg";
import NewDeviceIco from "../../../Assets/New_device_ico.svg";
import UserDeviceIco from "../../../Assets/User_device_ico.svg";
import ClientTable from "../../Common/ClientTable/ClientTable";
import columns from "./OrgDataModel";
import { useErrorHandler } from "react-error-boundary";
import { APIRequestFailedError, AuthError } from "../../../ErrorStates";
import { prefetchQuery, useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { useAuth } from "../../../hooks/useAuth";
// import { useAuth } from "../../../store/auth/AuthContext";

const OrgAssetsPage: React.FC = () => {
	const AssetIco = <img aria-hidden="true" className="card-icon" src={AllAssetIco}></img>;
	const FundIco = <img aria-hidden="true" className="card-icon" src={BusiFundedIco}></img>;
	const ItIco = <img aria-hidden="true" className="card-icon" src={ITFundIco}></img>;
	const NewIcon = <img aria-hidden="true" className="card-icon" src={NewDeviceIco}></img>;
	const UsedIco = <img aria-hidden="true" className="card-icon" src={UserDeviceIco}></img>;

	useEffect(() => {
		document.title = "Asset Inventory | My Organization Assets"
	}, [])

	const [selectedCategory, setSelectedCategory] = useState<number>(0);
	const { isLoading: isTableLoading, error: fetchTableError, data: orgData } = useCustomQuery([queryKeys.orgtable, selectedCategory],
		orgAssetsConfig({ category: selectedCategory }),
		{
			select: (data) => data.orgAssetsList ? data.orgAssetsList : [],
		}
	);
	const { isLoading: isCardLoading, error: fetchCardError, data: cardData } =
		useCustomQuery([queryKeys.orgcount], orgAssetsCountConfig(),
			{
				select: (data): {
					[key: number]: number | string
				} => {
					let resData = data.result;
					return {
						0: resData.allAssetsCount,
						1: resData.usedAssetsCount,
						2: resData.newAssetsCount,
						3: resData.itFundedAssetsCount,
						4: resData.buFundedAssetsCount,
					}
				},
			})

	const [error, setError] = useState<string | null>(null)

	const cardConf = ([
		{
			icon: AssetIco,
			category: 0,
			title: "Total Assets",
		},
		{
			icon: FundIco,
			category: 4,
			title: "BU Funded",
		},
		{
			icon: ItIco,
			category: 3,
			title: "IT Funded",
		},
		{
			icon: NewIcon,
			category: 2,
			title: "New Devices",
		},
		{
			icon: UsedIco,
			category: 1,
			title: "Used Devices",
		},
	]);
	const [skipStateReset, setSkipStateReset] = useState<boolean>(true);

	const handleError = useErrorHandler();

	useEffect(() => {
		if (fetchTableError) {
			if (fetchTableError instanceof APIRequestFailedError) {
				if (fetchTableError.statusCode === "201") {
					setError("No Record Found")
				} else if (fetchTableError.statusCode === "204")
					setError(fetchTableError.message)
				else
					handleError(fetchTableError)
			} else if (fetchTableError instanceof AuthError) {
				handleError(fetchTableError)
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				)
			}
		}
	}, [fetchTableError, handleError])


	useEffect(() => {
		setSkipStateReset(false); // To reset table's internal state
	}, [orgData]);

	// const auth = useAuth();

	const prefetchTableData = async (category) => {
		await prefetchQuery(
			[queryKeys.orgtable, category],
			orgAssetsConfig({ category: category }),
			{
				select: (data) => data.orgAssetsList ? data.orgAssetsList : [],
				staleTime: 100 * 1000, // only prefetch if older than 10 seconds
				// retry: true
			},
			false,
			// auth
		)
	}

	return (
		<>
			<div className="dds__container-fluid dds__px-0">
				<PageTitle>My Organization Assets</PageTitle>

				<FilterCardWrapper>
					{cardConf.length > 0 &&
						cardConf.map(item => (

							<Card
								ListItem={item}
								setSelectedCategory={setSelectedCategory}
								selected={item.category === selectedCategory}
								isLoadingTable={isTableLoading}
								isLoadingCards={isCardLoading}
								count={cardData ? cardData[item.category] : "NA"}
								onHover={() => prefetchTableData(item.category)}
							/>
						))}
				</FilterCardWrapper>
				{orgData && !fetchTableError && orgData && (
					<ClientTable
						data={orgData}
						columns={columns}
						skipStateReset={skipStateReset}
						activateSearch
					/>
				)}
				{fetchTableError && error !== 'No Record Found' && <p>{error}</p>}
			</div>
		</>
	);
};

export default OrgAssetsPage;
