import { objectToQuery } from "../../../utils/querycreator";

const baseUrl = process.env.REACT_APP_BASE_URL;
export const getAdminsBlockedConfig = () => {
	return {
		url: baseUrl + "/api/Fetch/v1/FetchAdminBlockDevices",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const deleteAdminsBlockedConfig = (emailId: string) => {
	// id:String
	return {
		url: baseUrl + "/api/v1/DeleteAdminBlockDevices?" + objectToQuery({ email: emailId }),
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const createUpdateAdminsBlockedConfig = (mode:"edit"|"new") => {
	if (mode === "new") {
		return {
			url: baseUrl + "/api/Add/v1/CreateAdminBlockDevices",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		};
	} else {
		return {
			url: baseUrl + "/api/v1/UpdateAdminBlockDevices",
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
		};
	}
};