import { useEffect, useRef, useState } from "react";
import { Drawer } from "@dds/components";
import { createPortal } from "react-dom";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";
import { useDashboardContext } from "../../../store/dashboard/DashboardContext";
import useResponsiveDragLayout from "../DashboardPage/useResponsiveDragLayout";
import QuickViewPopover from "./QuickViewPopover";
import { activeType } from "../constants";

const DrawerHeader: React.FC<{ closeDrawer: () => void }> = ({
	closeDrawer,
}) => (
	<div className="dds__drawer__header dds__d-flex dds__justify-content-between">
		<button type="button" className="dds__drawer__close" onClick={closeDrawer}>
			Back
		</button>
	</div>
);

const DrawerBody: React.FC<{
	cardName: string;
	active: activeType | undefined;
	closeDrawer: () => void;
	openCardDrawer: (item: string, active: activeType) => void;
}> = ({ cardName, active, closeDrawer, openCardDrawer, children }) => {
	return (
		<div className="dds__drawer__body" style={{ marginBottom: "32px" }}>
			<div className="dds__d-flex dds__justify-content-between">
				<h2 className="dds__drawer__title">{cardName}</h2>
				<div>
					<QuickViewPopover
						cardName={cardName}
						active={active}
						closeDrawer={closeDrawer}
						openCardDrawer={openCardDrawer}
					/>
				</div>
			</div>
			{children}
		</div>
	);
};

const DDSDrawer: React.FC<{
	cardName: string;
	triggerID: string;
}> = ({ cardName, triggerID, children }) => {
	const drawerRef = useRef<HTMLDivElement>(null);
	const drawerInstanceRef = useRef<any>(null);
	const [portalElement, setPortalElement] = useState<any>(null);
	const { addEventToQueue } = useAnalytics();
	const { active } = useDashboardContext();
	const { openCardDrawer } = useResponsiveDragLayout();

	const logDrawerEvent = () => {
		console.log("Drawer opened", cardName);
		addEventToQueue({
			...eventsInfo["dashboard drawer open"](),
			additional_payload: {
				cardName,
				active,
			},
		});
	};
	// Initialize Drawer
	useEffect(() => {
		if (drawerRef.current) {
			drawerInstanceRef.current = new Drawer(drawerRef.current);
			// Event to listen when drawer opens
			drawerRef.current.addEventListener(
				"ddsDrawerOpenedEvent",
				logDrawerEvent
			);
		}
		() => {
			drawerRef.current?.removeEventListener(
				"ddsDrawerOpenedEvent",
				logDrawerEvent
			);

			drawerInstanceRef.current.dispose();
		};
	}, [portalElement]);

	const closeDrawer = () => {
		drawerInstanceRef.current.close();
	};

	// Create portal element
	const portalID = `portal-${triggerID}`;
	useEffect(() => {
		if (document.getElementById(portalID))
			setPortalElement(document.getElementById(portalID));
		else {
			const el = document.createElement("div");
			el.setAttribute("id", portalID);
			document.body.appendChild(el);
			setPortalElement(el);
		}
		// add callback to destroy portal element
		return () => {
			const el = document.getElementById(portalID);
			if (el) {
				el.remove();
			}
		};
	}, []);

	return (
		<div>

			{portalElement
				? createPortal(
					<div
						ref={drawerRef}
						className="dds__drawer"
						aria-hidden="true"
						data-dds="drawer"
						id={"#" + triggerID + "-drawer"}
						data-trigger={"#" + triggerID}
						tabIndex={-1}
					>
						<div className="dds__drawer__overlay" aria-hidden="true"></div>
						<div className="dds__drawer__container">
							<DrawerHeader closeDrawer={closeDrawer} />
							<DrawerBody
								cardName={cardName}
								active={active}
								closeDrawer={closeDrawer}
								openCardDrawer={openCardDrawer}
							>
								{children}
							</DrawerBody>
						</div>
					</div>,
					portalElement
				)
				: null}
		</div>
	);
};

export default DDSDrawer;
