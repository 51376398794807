import { Modal } from "@dds/components";
import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./vanillaModalReactClashFix.css";

let portalElement;
if (document.getElementById("overlays"))
  portalElement = document.getElementById("overlays")
else {
  const el = document.createElement("div")
  el.setAttribute('id', 'overlays')
  document.body.appendChild(el)
  portalElement = el
}

const DDSModal: React.FC<{
  classes: string;
  id: string;
  title: string;
  themeColor: string,
  iconClass?: string,
  onClose?: () => void,
  disposeOnClose?: boolean,
  footerButtons: {
    class: string;
    text: string | JSX.Element;
    handleFooterButtonClick: () => void;
    close?: boolean
    disabled?: boolean
  }[];
  modalInstanceRef: React.MutableRefObject<any>
}> = ({ modalInstanceRef, ...props }) => {

  const modalRef = useRef<HTMLDivElement>(null);

  const onCloseHandler = () => {
    // Dispose Modal
    if (props.disposeOnClose)
      modalInstanceRef.current.dispose();

    if (props.onClose)
      props.onClose();
  }

  useEffect(() => {
    modalInstanceRef.current = new Modal(modalRef.current);
    addEventListener('ddsModalClosedEvent', onCloseHandler)
    return () => {
      // modalInstanceRef.current.dispose();
      removeEventListener('ddsModalClosedEvent', onCloseHandler);
    }
  }, []);

  const handleClose = (e: any) => {
    modalInstanceRef.current.close();
  }

  let footerButtons: JSX.Element[] = [];
  if (props.footerButtons) {
    footerButtons = props.footerButtons.map((foob, indx) => {
      return (
        <button
          key={indx}
          data-index={indx}
          disabled={foob.disabled ? true : false}
          className={
            foob.class
              ? "dds__button " + foob.class
              : "dds__button dds__button--md"
          }
          onClick={foob.close ? handleClose : foob.handleFooterButtonClick}
        >
          {foob.text}
        </button>
      );
    });
  }

  return (
    <>
      {portalElement ? ReactDOM.createPortal(<div
        ref={modalRef}
        role="dialog"
        data-trigger={`#ddswrappermodal-${props.id}`}
        className={props.classes ? "dds__modal dds__modal_fix " + props.classes : "dds__modal dds__modal_fix"}
        aria-labelledby={`dds__modal-title-${props.id}`}
      >
        <div className="dds__modal__content" style={{
          borderRadius: 24
        }}>
          <div className="dds__modal__header">
            <h3 className="dds__modal__title dds__d-flex" id={`dds__modal-title-${props.id}`}>
              {props.iconClass && <i style={{ color: `var(--${props.themeColor}-800)`, fontSize: '1.5rem' }} className={`dds__icon ${props.iconClass} dds__mr-3`} />}
              <span style={{
                color: `var(--${props.themeColor}-800)`
              }}>{props.title}</span>
            </h3>
          </div>

          <div className="dds__modal__body">{props.children}</div>

          <div className="dds__modal__footer">
            {footerButtons.map(foob => foob)}
          </div>
        </div>
      </div>, portalElement) : null
      }
    </>
  );
};

export default DDSModal;
