import styled from "styled-components";
export const ComingSoonWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;

	img {
		width: 65%;
		height: 65%;
		object-fit: contain;
		@media (max-width: 768px) {
			width: 80%;
			height: 80%;
		}

		@media (max-width: 480px) {
			width: 100%;
			height: 100%;
		}
	}
`