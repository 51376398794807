import DefaultCell from "../../../Table/ReusableTableCells/DefaultCell";
import { fetchBlockDevicesFailureLogsConfig, fetchBlockDevicesSuccessLogsConfig, fetchBlockDevicesTotalLogsConfig } from "../../API";
import RecordDetailsModal from "./RecordDetailsModal/RecordDetailsModal";

const columns: any[] = [
	{
		Header: "File Name",
		accessor: "fileName",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <strong>{value}</strong>
		}

	},
	{
		Header: "File ID",
		accessor: "fileID",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <strong>{value}</strong>
		}

	},
	{
		Header: "Upload Type",
		accessor: "uploadType",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span>{value}</span>
		},
	},
	{
		Header: "Total Records",
		accessor: "totalRecords",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: (props) => RecordDetailsModal({ ...props, heading: "Total Records", apiConfig: fetchBlockDevicesTotalLogsConfig })
	},
	{
		Header: "Success",
		accessor: "totalSuccess",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: (props) => RecordDetailsModal({ ...props, heading: "Success", apiConfig: fetchBlockDevicesSuccessLogsConfig })
	},
	{
		Header: "Failed",
		accessor: "totalFailure",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: (props) => RecordDetailsModal({ ...props, heading: "Failed", apiConfig: fetchBlockDevicesFailureLogsConfig })
	},
	{
		Header: "Uploaded on",
		accessor: "uploadedOn",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => { // TODO
			return <em>{value}</em>
		},
	},

];
export default columns;