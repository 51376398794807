import React, { useEffect, useRef, useState } from 'react'
import useDateRangePicker from '../../../Common/DateRangePicker/useDateRangePicker';
import ServerTable from '../../../Common/ServerTable/ServerTable';
import FilterBar from '../../BlockedDevicesPage/FilterBar/FilterBar';
import { Dropdown } from "@dds/components"
import columns from './DataModel';
import { fetchBlockedDevicesConfig, fetchBulkUploadLogsConfig } from '../../../QuarantinePortal/API';
import { UnauthorizedError } from '../../../../ErrorStates';
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import { quarantine_portal } from '../../../Dashboardv3/constants';

type BlockedDevicesFilter = {
	startDate: string;
	endDate: string;
	isDateRange: boolean;
	status: "All" | "Blocked" | "Unblocked";
};
const BulkUploadLogsPage = () => {
	const sdRef = useRef<HTMLDivElement>(null);
	const edRef = useRef<HTMLDivElement>(null);
	const statusRef = useRef<HTMLDivElement>(null);
	const statusInstanceRef = useRef<any>(null);

	useEffect(() => {
		document.title = "Quarantine Portal | Bulk Upload Logs";
	}, []);
	const user = useUserInfo().getUserRole(quarantine_portal);
	useEffect(() => {
		if (!user.admin && !user.superAdmin)
			throw new UnauthorizedError()
	}, [])


	const [filter, setFilter] = useState<BlockedDevicesFilter>({
		startDate: "",
		endDate: "",
		status: "All",
		isDateRange: false,
	}); // set type
	const [reload, setReload] = useState<boolean>(false);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [tableError, setTableError] = useState<Error | null>(null);

	const {
		initialize: initializeDatePicker,
		getSelectedRange,
		clearDateRange,
	} = useDateRangePicker(sdRef, edRef);

	useEffect(() => {
		// init dropdown statusRef
		statusInstanceRef.current = new Dropdown(statusRef.current);
		statusInstanceRef.current.selectOption("All")
	}, []);

	useEffect(() => {
		if (filter) {
			setReload(true);
		}
	}, [filter]);

	useEffect(() => {
		initializeDatePicker("YYYY-MM-DD", new Date(), new Date());
	}, []);

	const applyHandler = () => {
		const { startDate, endDate } = getSelectedRange();
		console.log("statusInstanceRef2", statusInstanceRef.current);
		const status = statusInstanceRef.current.getSelection();
		if (startDate === null || endDate === null) {
			clearDateRange();
		}
		let isDateRange = startDate && endDate ? true : false;
		console.log({
			startDate,
			endDate,
			isDateRange,
		});
		setFilter({
			startDate,
			endDate,
			status,
			isDateRange,
		});
	};
	const resetHandler = () => {
		statusInstanceRef.current.selectOption("All");
		clearDateRange();
		applyHandler();
	};

	return (
		<div>
			<div className="dds__d-flex dds__flex-wrap dds__justify-content-between dds__mb-2 ">
				{/* <AppliedFilterBar */}
				<FilterBar
					statusList={["All", "Block", "Unblock"]}
					sdRef={sdRef}
					edRef={edRef}
					statusRef={statusRef}
					applyHandler={applyHandler}
					resetHandler={resetHandler}
					tableLoading={tableLoading}
				/>
			</div>
			{/* Table */}
			{filter && (
				<ServerTable
					apiArgs={[filter]} // add filters
					apiConfig={fetchBulkUploadLogsConfig}
					columns={columns}
					searchHeader="Search"
					heading="Bulk Upload Logs"
					tableBorder={false}
					disableHeading
					reload={reload}
					setReload={setReload}
					setTableLoadingState={setTableLoading}
					setTableErrorState={setTableError}
					disableColumnFunctions
					activateExportAllData={false}
				/>
			)}
		</div>
	);
}

export default BulkUploadLogsPage