import React, {
	ChangeEventHandler,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import { createDate, dateFormat } from "../../utils/dateFunctions";
import { IUserInfo } from "../../common/modules";
import { columns } from "./DataModel";
import { fetchDeliveryReportSummaryConfig } from "./API";
import { APIRequestFailedError, AuthError } from "../../ErrorStates";
import ClientTable from "../Common/ClientTable/ClientTable";
import useAxios from "../../hooks/useAxios";
import { useUserInfo } from "../../store/auth/UserInfoContext";
import LoadingSpinner from "../Common/LoadingSpinner";

const SummaryPortal: React.FC = () => {
	const today = new Date();
	const TEMP = 0;
	const lastMonth = new Date(
		today.getFullYear(),
		today.getMonth() - 1 - TEMP,
		today.getDate()
	);

	const [data, setData] = useState<any[]>([]);

	const [startDate, setstartDate] = useState<string>(
		dateFormat(lastMonth, "dd-mm-yyyy")
	);
	const [endDate, setendDate] = useState<string>(
		dateFormat(today, "dd-mm-yyyy")
	);
	const [region, setRegion] = useState<string[]>([]);
	const [globalFilterType, setGlobalFilterType] = useState<string>("daterange");
	const [reference, setReference] = useState("");
	const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo);
	const [userRole, setUserRole] = useState<string | null>(null);
	const [regionList, setRegionList] = useState<string[][] | null>(null);
	const [error, setError] = useState<string | null>(null);

	const {
		isLoading,
		error: dataError,
		sendRequest: fetchSummaryData,
	} = useAxios();
	const alert = useAlert();
	const history = useHistory();
	const handleError = useErrorHandler();

	const user = useUserInfo();

	// Set Summary Data
	const transformData = useCallback((dataObj: any) => {
		const loadedAssetData: any = [];
		for (const report in dataObj.arrSummaryReports) {
			const data: any = {};
			Object.values(columns).forEach(val => {
				const { accessor } = val;
				if (dataObj.arrSummaryReports[report][accessor] != null)
					// if(accessor!=="created_date")
					data[accessor] = `${dataObj.arrSummaryReports[report][val.accessor]}`;
				else data[accessor] = "";
			});

			loadedAssetData.push(data);
		}

		setData(loadedAssetData);
	}, []);

	const fetchData = useCallback(
		(startDate, endDate, region, reference, globalFilterType) => {
			setError(null);

			if (region.length > 0) {
				if (globalFilterType === "daterange") {
					fetchSummaryData(
						fetchDeliveryReportSummaryConfig({
							endDate: dateFormat(createDate(endDate), "dd-mm-yyyy"),
							startDate: dateFormat(createDate(startDate), "dd-mm-yyyy"),
						}),
						transformData
					);
				} else if (globalFilterType === "reference") {
					// Reference
				}
			}


			// console.log(dateFormat(createDate(startDate), "yyyy-mm-dd"));
			// console.log(dateFormat(createDate(endDate), "yyyy-mm-dd"));
			// console.log(region)
		},
		[fetchSummaryData, transformData]
	);

	useEffect(() => {
		// console.log(dataError)
		if (dataError) {
			if (dataError instanceof APIRequestFailedError) {
				if (dataError.statusCode !== "201") {
					handleError(dataError);
				} else {
					setError("No Vulnerability Record Found.");
				}
			} else if (dataError instanceof AuthError) {
				handleError(dataError);
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				);
			}
		}
	}, [dataError, handleError]);

	useEffect(() => {
		if (user) {
			const res = user?.getUserInfo();
			setUserInfo(res);
			setUserRole(res.userRole.role);
			let flag = 0;

			res.userRole.arrApproveCity.forEach(obj => {
				if (obj.isDefault === true) {
					setRegion([obj.cityname, obj.countryname]);
					flag = 1;
					// console.log("FETCHING AFTER SETTING REGION")
					fetchData(
						startDate,
						endDate,
						[obj.cityname, obj.countryname],
						reference,
						globalFilterType
					); // should run as a callback to setRegion()
				}
			});
			if (res.userRole.arrApproveCity.length > 0 && flag === 0) {
				setRegion([
					res.userRole.arrApproveCity[0].cityname,
					res.userRole.arrApproveCity[0].countryname,
				]); // setting region to default one
				// console.log("FETCHING AFTER SETTING REGION - ND")
				fetchData(
					startDate,
					endDate,
					[
						res.userRole.arrApproveCity[0].cityname,
						res.userRole.arrApproveCity[0].countryname,
					],
					reference,
					globalFilterType
				);
			}
			const temp: string[][] = [];
			res.userRole.arrApproveCity.forEach(obj => {
				temp.push([obj.cityname, obj.countryname]);
			});
			setRegionList(temp);
			// setCanFetchData(true)
		}
	}, [user]);

	return (
		<>
			{isLoading && !error && (
				<div className="dds__mt-3 dds__mx-auto dds__text-center">
					<LoadingSpinner size="md" label="Fetching" />
					{/* <h4 className="dds_h4">Fetching</h4> */}
				</div>
			)}
			{error}
			{!isLoading && !error && (
				<ClientTable
					data={data}
					columns={columns}
					skipStateReset={false}
					disableToolbar
				/>
			)}
		</>
	);
};

export default SummaryPortal;