
type DisplayUserRoleButtonsProps = {
  Name: string;
};

export const DisplayUserRoleButton = (ButtonInfo: DisplayUserRoleButtonsProps) => {
  // const tagInstanceRef = useRef<any>(null);
  // useEffect(() => {
  //   const element = document.getElementsByClassName(ButtonInfo.Name);
  //   console.log("Element", element);
  //   tagInstanceRef.current = new Tag(element, {dismiss:true});
  //   console.log("Tag Instance", tagInstanceRef.current);
  // },[ButtonInfo.Name]);
  
  return (
    <div data-dds="tag" className="dds__tag dds__tag--sm"  data-sr-dismiss="dismiss" >
      <button type="button" >{ButtonInfo.Name}</button>
    </div>
  );
};
