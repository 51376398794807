/* eslint-disable react/destructuring-assignment */
import React, { ChangeEventHandler, Fragment, MouseEventHandler, useRef, useState } from "react";
import { ColumnInstance, Filters, Row } from "react-table";
import ReactTooltip from "react-tooltip";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { GlobalSearchFilter } from "../GlobalSearchFilter";
import ColumnShowAdjust from "./ColumnShowAdjust";
import ColumnWidthAdjust from "./ColumnWidthAdjust";
import FiltersAdjust from "./FiltersAdjust";

import { ReactComponent as ColWidthIcon } from "../../../Assets/Column Width.svg";
import { ReactComponent as ColViewIcon } from "../../../Assets/dds2_view-column.svg";
import { ReactComponent as FilterIcon } from "../../../Assets/DDS2 Filter.svg";
import { ReactComponent as CrunchRowIcon } from "../../../Assets/Crunch Row.svg";
import { ReactComponent as UncrunchRowIcon } from "../../../Assets/Uncrunch Row.svg";
import { ReactComponent as SearchIcon } from "../../../Assets/Search.svg"
import { ReactComponent as ExportIcon } from "../../../Assets/ExportCSV.svg"
import { IconButton } from "../TableStyles";
import LoadingSpinner from "../../Common/LoadingSpinner";

const TableToolbar: React.FC<{
	allColumns: ColumnInstance<object>[];
	resetResizing: any;
	onCrunchClick: () => void;
	isCmplx: boolean;
	preGlobalFilteredRows: Row<object>[];
	globalFilter: any;
	setGlobalFilter: (filterValue: any) => void;
	filterState: Filters<object>;
	activateFilters: boolean;
	exportData: (filetype: string, allData: boolean, filename?: string) => void;
	activateExportAllData: boolean;
	activateExportCurrentView: boolean;
	activateSearch: boolean;


	serverSideSearch?: boolean
	searchInputChangeHandler?: ChangeEventHandler<HTMLInputElement>
	searchHandler?: any
	isFetching?: boolean
	searchHeader?: string
	searchOnly?: boolean


	serverSideExport?: boolean
	exportHandler?: MouseEventHandler<HTMLButtonElement>
	isExporting?: boolean
	disableExportNull: boolean


	disableColumnFunctions?: boolean

}> = props => {
	const [colToolIsShown, setColToolIsShown] = useState<boolean>(false);
	// const showColTool = (): void => setColToolIsShown(true);
	// const hideColTool = (): void => setColToolIsShown(false);

	const [filToolIsShown, setFilToolIsShown] = useState<boolean>(false);
	// const showFilTool = (): void => setFilToolIsShown(true);
	// const hideFilTool = (): void => setFilToolIsShown(false);

	const [colWidthToolIsShown, setColWidthToolIsShown] =
		useState<boolean>(false);
	// const showColWidthTool = (): void => setColWidthToolIsShown(true);
	// const hideColWidthTool = (): void => setColWidthToolIsShown(false);

	let flag = 0;

	// if(props.filterState.length === 1){
	// 	props.filterState.forEach(element => {
	// 		if(element["id"] === "created_date")
	// 			if(element["value"][0]===undefined && element["value"][1]===undefined)
	// 				flag=1;
	// 			else
	// 				flag=0;
	// 	});
	// }
	if (props.filterState.length === 0) flag = 1;

	const filterBtnClasses =
		flag === 1
			? "dds__button"
			: "dds__button";

	const exportAllDataHandler = (): void => {
		props.exportData("csv", true);
	};
	const exportCurrentViewHandler = (): void => {
		props.exportData("csv", false);
	};

	const clickMeButtonRef =
		useRef() as React.MutableRefObject<HTMLInputElement | null>;

	return (
		<div data-testid="table-toolbar">
			<div className="dds__d-flex dds__justify-content-between dds__flex-wrap dds__py-2 dds__px-4"
				style={{
					backgroundColor: `${props.searchOnly ? "initial" : "var(--blue-600)"}`,
					borderRadius: 10
				}}>
				{props.activateSearch && !props.serverSideSearch && (
					<div className="dds__d-flex dds__py-1 dds__flex-grow-1">
						<div className="dds__w-100">
							<GlobalSearchFilter
								preGlobalFilteredRows={props.preGlobalFilteredRows}
								globalFilter={props.globalFilter}
								setGlobalFilter={props.setGlobalFilter}
							/>
						</div>
					</div>
				)}
				{props.activateSearch && props.serverSideSearch && (
					<div
						className={
							"dds__d-flex dds__py-1 dds__flex-grow-1 dds__input-text__wrapper dds__input-text__wrapper--button dds__input-text__container--sm"}
					>
						<input
							style={{
								width: '100%',
								maxWidth: `${props.searchOnly ? "100%" : "300px"}`,
							}}
							disabled={props.isFetching}
							onChange={props.searchInputChangeHandler}
							onKeyPress={e => e.key === "Enter" && props.searchHandler()}
							type="text"
							className="dds__input-text"
							placeholder={props.searchHeader}
						/>

						<button aria-label="Search"
							style={{ minWidth: 50, backgroundColor: 'white' }}
							className="dds__button dds__button--secondary dds__button--md"
							type="button"
							onClick={props.searchHandler}
							disabled={props.isFetching}
						>
							<SearchIcon
								style={{
									margin: 0,
								}}
								className="dds__icon dds__button__icon--end"
							/>
						</button>
					</div>
				)}
				<div className="dds__d-flex dds__py-1 dds__flex-wrap dds__justify-content-center">
					{!props.searchOnly && <Fragment>

						{!props.disableColumnFunctions &&
							<>
								{props.activateFilters && (
									<div>
										<Popover
											aria-labelledby="filterbtn"
											containerStyle={{ zIndex: "2" }}
											isOpen={filToolIsShown}
											positions={["bottom", "right", "left", "top"]}
											padding={10}
											onClickOutside={() => setFilToolIsShown(false)}
											ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
											content={({ position, childRect, popoverRect }) => (
												<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
													position={position}
													childRect={childRect}
													popoverRect={popoverRect}
													arrowColor="gray"
													arrowSize={10}
													arrowStyle={{ opacity: 1 }}
													className="popover-arrow-container"
													arrowClassName="popover-arrow"
													data-testid="filterPopup"
												>
													<div
														style={{
															backgroundColor: "white",
															opacity: 1,
															boxShadow:
																"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
															overflowY: "auto",
														}}
													>
														<FiltersAdjust allColumns={props.allColumns} />
													</div>
												</ArrowContainer>
											)}
										>
											<IconButton
												onClick={() => setFilToolIsShown(!filToolIsShown)}
												className={`${filterBtnClasses}`}
												data-for="filterTip"
												data-tip="Filters"
												type="button"
												aria-label="Filters"
												aria-expanded={filToolIsShown}
												id="filterbtn"
											>
												<FilterIcon aria-hidden="true" />
												<ReactTooltip id="filterTip" />
											</IconButton>
										</Popover>
									</div>
								)}

								<div>
									{!props.isCmplx && (

										<button onClick={props.onCrunchClick} className="dds__button dds__button__icon" type="button" aria-label="crunch row" data-for="crunchTip"
											data-tip="Crunch Rows"
										>
											<CrunchRowIcon aria-hidden="true" />
											<ReactTooltip id="crunchTip" />
										</button>
									)}
									{props.isCmplx && (

										<button
											onClick={props.onCrunchClick}
											data-for="uncrunchTip"
											data-tip="Normal View"
											className="dds__button dds__button__icon dds__ml-2"
											type="button"
											aria-label="Normal rows">
											<UncrunchRowIcon aria-hidden="true" />
											<ReactTooltip id="uncrunchTip" />
										</button>
									)}
								</div>

								<div>
									<Popover
										aria-labelledby="colbtn"
										containerStyle={{ zIndex: "2" }}
										isOpen={colToolIsShown}
										positions={["bottom", "right", "left", "top"]}
										padding={10}
										onClickOutside={() => setColToolIsShown(false)}
										ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
										content={({ position, childRect, popoverRect }) => (
											<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
												position={position}
												childRect={childRect}
												popoverRect={popoverRect}
												arrowColor="gray"
												arrowSize={10}
												arrowStyle={{ opacity: 1 }}
												className="popover-arrow-container"
												arrowClassName="popover-arrow"

											>
												<div
													style={{
														maxWidth: 400,
														backgroundColor: "white",
														opacity: 1,
														boxShadow:
															"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
														overflow: "auto",
													}}
													data-testid="columnShowHidePopup"

												>
													<ColumnShowAdjust allColumns={props.allColumns} />
												</div>
											</ArrowContainer>
										)}
									>
										<button
											id="colbtn"
											onClick={() => setColToolIsShown(!colToolIsShown)}
											className="dds__button dds__button__icon dds__ml-2"
											data-for="showHideColTip"
											data-tip="Show Hide Columns"
											type="button"
											aria-label="Show hide columns"
											aria-expanded={colToolIsShown}
										>
											<ColViewIcon aria-hidden="true" />
											<ReactTooltip id="showHideColTip" />
										</button>
									</Popover>
								</div>
								<div>
									<Popover
										aria-labelledby="colwidthbtn"
										containerStyle={{ zIndex: "2" }}
										isOpen={colWidthToolIsShown}
										positions={["bottom", "right", "left", "top"]}
										padding={10}
										onClickOutside={() => setColWidthToolIsShown(false)}
										ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
										content={({ position, childRect, popoverRect }) => (
											<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
												position={position}
												childRect={childRect}
												popoverRect={popoverRect}
												arrowColor="gray"
												arrowSize={10}
												arrowStyle={{ opacity: 1 }}
												className="popover-arrow-container"
												arrowClassName="popover-arrow"
											>
												<div
													style={{
														maxWidth: 500,
														backgroundColor: "white",
														opacity: 1,
														boxShadow:
															"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
														overflowY: "auto",
													}}
												>
													<ColumnWidthAdjust
														allColumns={props.allColumns}
														resetResizing={props.resetResizing}
													/>
												</div>
											</ArrowContainer>
										)}
									>
										<button
											id="colwidthbtn"
											onClick={() => setColWidthToolIsShown(!colWidthToolIsShown)}
											className="dds__button dds__button__icon dds__ml-2"
											data-for="colWidthTip"
											data-tip="Adjust Column Width"
											type="button"
											aria-label="Adjust column width"
											aria-expanded={colWidthToolIsShown}
										>
											<ColWidthIcon aria-hidden="true" />
											<ReactTooltip id="colWidthTip" />
										</button>
									</Popover>
								</div>
							</>
						}
						{props.activateExportAllData && props.serverSideExport && (
							<div>
								<button
									disabled={props.disableExportNull || props.isExporting}
									onClick={props.exportHandler}
									className="dds__button dds__button--md dds__button__icon dds__ml-3"
									type="button"
									style={{
										// lineHeight: "2.2rem",
										color: 'white',
										borderColor: 'white'
									}}
									aria-label="Export data to CSV"
								>
									<i className=" dds__button__icon--start" aria-hidden="true" >
										<ExportIcon />
									</i>
									{!props.isExporting && <span>Export to CSV</span>}
									{props.isExporting && (
										<>
											<span aria-label="Exporting" className="dds__mr-2">Exporting</span>
											<LoadingSpinner size='sm' />
										</>
									)}
								</button>
							</div>
						)}
						{props.activateExportAllData && !props.serverSideExport && (
							<div>
								<button
									type="button"
									disabled={props.disableExportNull}
									onClick={exportAllDataHandler}
									className="dds__button dds__button__icon dds__button--md dds__ml-3"
									style={{
										color: 'white',
										borderColor: 'white'
									}}
									aria-label="Export all data"
								>
									<i className=" dds__button__icon--start" aria-hidden="true" >
										<ExportIcon />
									</i>
									{props.activateExportAllData && !props.activateExportCurrentView ? 'Export' : 'Export All Data'}
									<span className="dds__sr-only">Export data withoult any filter or search applied</span>
								</button>
							</div>
						)}
						{props.activateExportCurrentView && !props.serverSideExport && (
							<div>
								<button
									type="button"
									disabled={props.disableExportNull}
									onClick={exportCurrentViewHandler}
									className="dds__button dds__button__icon dds__button--md  dds__ml-3"
									style={{
										// lineHeight: "1.7rem",
										color: 'white',
										borderColor: 'white'
									}}
									aria-label="Export current data"
								>
									<i className=" dds__button__icon--start" aria-hidden="true" >
										<ExportIcon />
									</i>
									Export Current
									<span className="dds__sr-only">Export data with filters and search if applied</span>
								</button>
							</div>
						)}
					</Fragment>}
				</div>
			</div>
		</div>
	);
};
export default TableToolbar;
