import React, { useEffect, useRef, useState } from 'react'
import { Search } from "@dds/components";

export const useSearch = (onSubmit?: () => void, reinit?:any) => {
    const [searchText, setSearchText] = useState<string>("");
    const searchInstanceRef = useRef<any>(null);
    const searchRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        searchInstanceRef.current = new Search(searchRef.current, {
            srSearchButtonText: "Search button",
            srClearButtonText: "Clear search button",
        });
        searchRef.current?.addEventListener("ddsSearchClearEvent", () => {
            setSearchText("");
        });
        searchRef.current?.addEventListener("ddsSearchSubmitEvent", (e: any) => {
            setSearchText(e.detail.query);
            if (onSubmit) {
                onSubmit();
            }
        });
        () => {
            searchInstanceRef.current?.dispose();
        };
    }, [reinit]);

    return {
        searchText, setSearchText, searchRef
    }
}
