import { Fragment, useContext, useEffect, useRef, useState, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Dropdown } from "@dds/components";
import { useErrorHandler } from "react-error-boundary";
import ServerTable from "../../Common/ServerTable/ServerTable";
import VulnerabilityHeaderInfo from "../HeaderBar/VulnerabilityHeaderInfo";
import { ReactComponent as HeaderIcon } from "../../../Assets/software.svg";
import {
	getLeaderBoardConfig,
	productListConfig,
	searchLeadersConfig,
} from "./API";
import { ReactComponent as Icon } from "../../../Assets/dds2_user-group.svg";
import useAxios from "../../../hooks/useAxios";
import Modal from "../../Common/Modal/Modal";
import { APIRequestFailedError, AuthError, UnauthorizedError } from "../../../ErrorStates";
import ClientTable from "../../Common/ClientTable/ClientTable";
import { IconButton } from "../../Table/TableStyles";
import ExportButton from "./ExportButton";
import CardHeader from "./CardHeader";
import { CVLLeaderboardContext } from "./CVLLeaderboardContext";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import DropdownComponent from "../../Common/DropdownComponent";
import { cvl } from "../../Dashboardv3/constants";


// useAxios for cardData and context
const VulnerabilityLeaderboard: React.FC = () => {
	useEffect(() => {
		document.title = "Security Threats | Leaderboard";
	}, []);

	const { originalFilterList, selectedFilters, setSelectedFilters, isoriginalFilterListLoading } = useContext(CVLLeaderboardContext)

	console.log(useContext(CVLLeaderboardContext));

	const handleError = useErrorHandler();
	const user = useUserInfo();
	const [legalName, setLegalName] = useState<string>();
	const [admin, setAdmin] = useState(false);

	useEffect(() => {
		if (user) {
			const res = user?.getUserInfo();
			setLegalName(res.legalName);
			if (res.user_role[cvl[0]].admin || res.user_role[cvl[0]].superAdmin || res.user_role[cvl[0]].leader) {
				setAdmin(true)
			} else
				handleError(new UnauthorizedError())
		}
	}, [user, handleError]);

	const urlParams = useParams<{
		l3_manager?: string;
		l4_manager?: string;
		l5_manager?: string;
	}>();

	// console.log(urlParams)

	let current_level = "l3";

	if (urlParams.l3_manager) current_level = "l4";
	if (urlParams.l4_manager) current_level = "l5";
	if (urlParams.l5_manager) current_level = "l6";

	const {
		isLoading: productListLoading,
		error: productListError,
		sendRequest: fetchProductList,
	} = useAxios();

	const [productListVisible, setProductListVisible] = useState(false);
	const [productList, setProductList] = useState<{
		records: any[];
		leader: string;
	}>();

	const [overallProductListVisible, setOverallProductListVisible] =
		useState(false);
	const [overallProductList, setOverallProductList] = useState<any[]>([]);

	const [productListErrorMessage, setProductListErrorMessage] =
		useState<string>();
	useEffect(() => {
		if (productListVisible === false) setProductList(undefined);
	}, [productListVisible]);

	const openProductDetails = (params: { leader: string; level: string }) => {
		setProductListVisible(true);
		fetchProductList(productListConfig(params), data => {
			setProductList({ records: data.records, leader: params.leader });
		});
	};

	useEffect(() => {
		if (productListError) {
			if (productListError instanceof APIRequestFailedError) {
				if (productListError.statusCode === "201")
					setProductListErrorMessage("No Record Found");
				else handleError(productListError);
			} else if (productListError instanceof AuthError) {
				handleError(productListError);
			} else {
				setProductListErrorMessage(
					"Data is not loading at the moment. Please check your connection and try again."
				);
			}
		}
	}, [productListError]);

	const history = useHistory();

	const encodeHashUriComponent = (text: string) => {
		const b64encoding = window.btoa(text);
		return encodeURIComponent(b64encoding);
	};

	const decodeHashUriComponent = (text: string) => {
		const decodedUriComponent = decodeURIComponent(text);
		const b64decoding = window.atob(decodedUriComponent);
		return b64decoding;
	};

	const redirect = (
		current_level: string,
		leader: string,
		heirarchy: any,
		preencoded?: boolean
	) => {
		if (preencoded === false)
			setSelectedFilters(null); // clear filters if redirection happens from Search table
		const encoded_leader = encodeHashUriComponent(leader);
		const encoded_l3 = preencoded
			? heirarchy.l3
			: encodeHashUriComponent(heirarchy.l3);
		const encoded_l4 = preencoded
			? heirarchy.l4
			: encodeHashUriComponent(heirarchy.l4);
		if (current_level === "l3")
			history.push(`/vulnerability-portal/leaderboard/${encoded_leader}`);
		else if (current_level === "l4")
			history.push(
				`/vulnerability-portal/leaderboard/${encoded_l3}/${encoded_leader}`
			);
		else if (current_level === "l5")
			history.push(
				`/vulnerability-portal/leaderboard/${encoded_l3}/${encoded_l4}/${encoded_leader}`
			);
	};

	const productListColumns = [
		{
			Header: "Product",
			accessor: "product",
			disableFilters: true,
			disableSortBy: false,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{ display: "inline", color: "var(--red-700)" }}
						data-for="product_modal_tip"
						data-tip={value}
					>
						{value}
					</p>
					{/* <ReactTooltip id="product_modal_tip" /> */}
				</>
			),
		},
		{
			Header: "Client Vulnerability Count",
			accessor: "cvlcount",
			disableFilters: true,
			disableSortBy: false,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{ display: "inline" }}
						data-for="cvlcount_modal_tip"
						data-tip={value}
					>
						{new Number(value).toLocaleString()}
					</p>
					{/* <ReactTooltip id="cvlcount_modal_tip" /> */}
				</>
			),
		},
	];

	const commonColumns: any[] = [
		{
			Header: "Leader Name",
			accessor: "leader",
			disableFilters: true,
			disableSortBy: false,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{ display: "inline", color: "var(--blue-800)" }}
						data-for="leader_tip"
						data-tip={value}
					>
						{value}
					</p>
					<ReactTooltip id="leader_tip" />
				</>
			),
		},
		{
			Header: "Client Vulnerability Count",
			accessor: "cvlcount",
			disableFilters: true,
			disableSortBy: false,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{
							display: "inline",
							color: "var(--blue-800)",
							fontWeight: 500,
						}}
						data-for="cvlcount_tip"
						data-tip={value}
					>
						{new Number(value).toLocaleString()}
					</p>
					<ReactTooltip id="cvlcount_tip" />
				</>
			),
		},

		{
			Header: "Products count",
			accessor: "productcount",
			disableFilters: true,
			disableSortBy: false,
			// width: 40,
			Cell: ({ value }) => (
				<>
					<p
						style={{
							display: "inline",
							color: "var(--blue-800)",
							fontWeight: 500,
						}}
						data-for="productcount_tip"
						data-tip={value}
					>
						{new Number(value).toLocaleString()}
					</p>
					<ReactTooltip id="productcount_tip" />
				</>
			),
		},
		{
			Header: "Level",
			accessor: "level",
			show: false,
			disableFilters: true,
			disableSortBy: false,
			disableExport: true,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p style={{ display: "inline" }}>
						<b>{value.toUpperCase()}</b>
					</p>
				</>
			),
		},
		{
			Header: "Hierarchy",
			accessor: "hierarchy",
			show: false,
			disableFilters: true,
			disableSortBy: false,
			disableExport: true,
			// width: 80,
			Cell: ({ value }) => null,
		},
		{
			Header: "View Product details",
			accessor: "productname",
			disableFilters: true,
			disableSortBy: false,
			disableExport: true,
			width: 200,
			Cell: ({ value, row }) => {
				return (
					<div className="dds__w-100 dds__d-flex dds__justify-content-between">
						<div>
							<p
								style={{
									display: "inline",
									color: "var(--red-700)",
									fontWeight: 500,
								}}
								data-for="productname_tip"
								data-tip={value}
							>
								{value}
							</p>
							<ReactTooltip id="productname_tip" />
						</div>
						{parseInt(row.values["productcount"]) - 1 > 0 && (
							<div>
								<button
									style={{
										minWidth: "120px",
									}}
									onClick={() =>
										openProductDetails({
											leader: row.values["leader"],
											level: row.values["level"],
										})
									}
									className="dds__button dds__button--sm dds__button--secondary dds__ml-2 "
								>
									<i
										style={{ fontSize: "1.2rem" }}
										className="dds__icon dds__icon--eye-view-on dds__button__icon--start"
									/>
									+{parseInt(row.values["productcount"]) - 1} more
								</button>
							</div>
						)}
					</div>
				);
			},
		},
	];

	const leaderActionColumn = {
		Header: "Action",
		accessor: "nextlevel",
		disableFilters: true,
		disableSortBy: true,
		width: 180,
		show: true, // current_level === 'l6' ? false : true
		disableExport: true,
		Cell: ({ value, row, column }) => {
			return (
				<span>
					{((legalName &&
						legalName.toLowerCase() === row.values["leader"].toLowerCase()) ||
						admin) && (
							<ExportButton
								leader={row.values["leader"]}
								level={row.values["level"]}
							/>
						)}
					{value && (
						<>
							<button
								onClick={() =>
									redirect(
										row.values["level"],
										row.values["leader"],
										{
											l3: urlParams?.l3_manager,
											l4: urlParams?.l4_manager,
											l5: urlParams?.l4_manager,
										},
										true
									)
								}
								className="dds__button dds__button--sm dds__button--secondary dds__ml-2 dds__mb-1"
							>
								View {value.toUpperCase()}
								<i
									style={{ fontSize: "1.2rem" }}
									className="dds__icon dds__icon--arrow-right dds__button__icon--end"
								></i>
							</button>
						</>
					)}
				</span>
			);
		},
	};

	const searchActionColumn = {
		Header: "Action",
		accessor: "nextlevel",
		disableFilters: true,
		disableSortBy: true,
		disableExport: true,
		width: 180,
		Cell: ({ value, row, column }) => {
			// console.log(JSON.parse(row.values['hierarchy']))

			return (
				<span>
					{((legalName &&
						legalName.toLowerCase() === row.values["leader"].toLowerCase()) ||
						admin) && (
							<ExportButton
								leader={row.values["leader"]}
								level={row.values["level"]}
							/>
						)}
					{value && (
						<>
							<button
								onClick={() =>
									redirect(
										row.values["level"],
										row.values["leader"],
										JSON.parse(row.values["hierarchy"]),
										false
									)
								}
								className="dds__button dds__button--sm dds__button--secondary dds__ml-2"
							>
								View {value.toUpperCase()}
								<i
									style={{ fontSize: "1.2rem" }}
									className="dds__icon dds__icon--arrow-right dds__button__icon--end"
								></i>
							</button>
						</>
					)}
				</span>
			);
		},
	};

	const firstIconColumn = {
		Header: "#",
		accessor: "ico",
		disableFilters: true,
		disableSortBy: true,
		disableExport: true,
		width: 5,
		Cell: ({ }) => {
			return (
				<div
					style={{
						borderRadius: "50%",
						background: "#0076CE",
						width: 32,
						height: 32,
						textAlignLast: "center",
						position: "relative",
					}}
				>
					<i
						style={{
							fontSize: "0.8rem",
							color: "white",
							position: "absolute",
							top: "2%",
							right: "47%",
						}}
						className="dds__icon dds__icon--user dds__py-1 dds__px-2 dds__d-none dds__d-sm-block dds__mx-auto dds__pt-2"
					/>
				</div>
			);
		},
	};

	// const searchTableColumns = JSON.parse(JSON.stringify(commonColumns)).concat(searchActionColumn); // Stringifying to deep copy the array
	// searchTableColumns.forEach(element => {
	// 	element['disableSortBy'] = true
	// });
	const searchTableColumns: any[] = [
		{
			Header: "Leader Name",
			accessor: "leader",
			disableFilters: true,
			disableSortBy: true,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{ display: "inline", color: "var(--blue-800)" }}
						data-for="leader_tip"
						data-tip={value}
					>
						{value}
					</p>
					<ReactTooltip id="leader_tip" />
				</>
			),
		},
		{
			Header: "Client Vulnerability Count",
			accessor: "cvlcount",
			disableFilters: true,
			disableSortBy: true,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{
							display: "inline",
							color: "var(--blue-800)",
							fontWeight: 500,
						}}
						data-for="cvlcount_tip"
						data-tip={value}
					>
						{new Number(value).toLocaleString()}
					</p>
					<ReactTooltip id="cvlcount_tip" />
				</>
			),
		},

		{
			Header: "Products count",
			accessor: "productcount",
			disableFilters: true,
			disableSortBy: true,
			// width: 40,
			Cell: ({ value }) => (
				<>
					<p
						style={{
							display: "inline",
							color: "var(--blue-800)",
							fontWeight: 500,
						}}
						data-for="productcount_tip"
						data-tip={value}
					>
						{new Number(value).toLocaleString()}
					</p>
					<ReactTooltip id="productcount_tip" />
				</>
			),
		},
		{
			Header: "Level",
			accessor: "level",
			show: false,
			disableFilters: true,
			disableSortBy: true,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p style={{ display: "inline" }}>
						<b>{value.toUpperCase()}</b>
					</p>
				</>
			),
		},
		{
			Header: "Hierarchy",
			accessor: "hierarchy",
			show: false,
			disableFilters: true,
			disableSortBy: true,
			// width: 80,
			Cell: ({ value }) => null,
		},
		{
			Header: "View Product details",
			accessor: "productname",
			disableFilters: true,
			disableSortBy: true,
			width: 200,
			Cell: ({ value, row }) => {
				return (
					<div className="dds__w-100 dds__d-flex dds__justify-content-between">
						<div>
							<p
								style={{
									display: "inline",
									color: "var(--red-700)",
									fontWeight: 500,
								}}
								data-for="productname_tip"
								data-tip={value}
							>
								{value}
							</p>
							<ReactTooltip id="productname_tip" />
						</div>
						{parseInt(row.values["productcount"]) - 1 > 0 && (
							<div>
								<button
									style={{
										minWidth: "120px",
										float: "right",
									}}
									onClick={() =>
										openProductDetails({
											leader: row.values["leader"],
											level: row.values["level"],
										})
									}
									className="dds__button dds__button--sm dds__button--secondary dds__ml-2"
								>
									<i
										style={{ fontSize: "1.2rem" }}
										className="dds__icon dds__icon--eye-view-on dds__button__icon--start"
									/>
									+{parseInt(row.values["productcount"]) - 1} more
								</button>
							</div>
						)}
					</div>
				);
			},
		},
	];

	const leaderTableColumns = commonColumns.concat(leaderActionColumn);
	searchTableColumns.push(searchActionColumn);
	leaderTableColumns.unshift(firstIconColumn);
	searchTableColumns.unshift(firstIconColumn);
	// console.log(leaderTableColumns)
	// console.log(searchTableColumns)
	// console.log(commonColumns)

	const regionRef = useRef<HTMLDivElement>(null);
	// const regionInstanceRef = useRef<any>();
	const [regionInstance, setRegionInstance] = useState<any>(null);
	const countryRef = useRef<HTMLDivElement>(null);
	const [countryInstance, setCountryInstance] = useState<any>(null);
	// const countryInstanceRef = useRef<any>();
	const productRef = useRef<HTMLDivElement>(null);
	const [productInstance, setProductInstance] = useState<any>(null);
	// const productInstanceRef = useRef<any>();




	// const siteDropdownObject = {
	// 	India: [
	// 		{ id: 1, site: "Bangalore" },
	// 		{ id: 2, site: "Chennai" },
	// 		{ id: 3, site: "Hyderabad" },
	// 		{ id: 4, site: "Mumbai" },
	// 		{ id: 5, site: "Pune" },
	// 	],
	// 	USA: [
	// 		{ id: 1, site: "Atlanta" },
	// 		{ id: 2, site: "Austin" },
	// 		{ id: 3, site: "Boston" },
	// 		{ id: 4, site: "Chicago" },
	// 		{ id: 5, site: "Dallas" },
	// 		{ id: 6, site: "Denver" },
	// 		{ id: 7, site: "Houston" },
	// 		{ id: 8, site: "Los Angeles" },
	// 		{ id: 9, site: "New York" },
	// 		{ id: 10, site: "San Francisco" },
	// 		{ id: 11, site: "Seattle" },
	// 	],
	// 	Canada: [
	// 		{ id: 1, site: "Calgary" },
	// 		{ id: 2, site: "Montreal" },
	// 		{ id: 3, site: "Toronto" },
	// 		{ id: 4, site: "Vancouver" },
	// 	],
	// 	Australia: [
	// 		{ id: 1, site: "Adelaide" },
	// 		{ id: 2, site: "Brisbane" },
	// 		{ id: 3, site: "Melbourne" },
	// 		{ id: 4, site: "Perth" },
	// 		{ id: 5, site: "Sydney" },
	// 	],
	// 	UK: [
	// 		{ id: 1, site: "Birmingham" },
	// 		{ id: 2, site: "Edinburgh" },
	// 		{ id: 3, site: "Glasgow" },
	// 		{ id: 4, site: "London" },
	// 		{ id: 5, site: "Manchester" },
	// 	],
	// 	Singapore: [{ id: 1, site: "Singapore" }],
	// 	Malaysia: [{ id: 1, site: "Kuala Lumpur" }],
	// 	"New Zealand": [{ id: 1, site: "Auckland" }],
	// };




	// const [countryDropdownList, setcountryDropdownList] = useState<{ id: number, site: string }[]>([]);
	const [reload, setReload] = useState(false);
	useLayoutEffect(() => {
		// regionInstanceRef.current = new Dropdown(regionRef.current);
		// countryInstanceRef.current = new Dropdown(countryRef.current);
		// productInstanceRef.current = new Dropdown(productRef.current);
		if (originalFilterList) {
			console.log("Initializing dropdowns")
			setRegionInstance(new Dropdown(regionRef.current));
			setCountryInstance(new Dropdown(countryRef.current));
			setProductInstance(new Dropdown(productRef.current));
		}


		// regionRef.current!.addEventListener("ddsDropdownSelectionChangeEvent", (e: any) => {
		// 	console.log("dropdown", e.detail.value);
		// 	let allSitesinSelectedCountries: { id: number, site: string }[] = [];
		// 	for (let i = 0; i < e.detail.value.length; i++) {
		// 		allSitesinSelectedCountries = allSitesinSelectedCountries.concat(siteDropdownObject[e.detail.value[i]]);
		// 	}
		// 	setcountryDropdownList(allSitesinSelectedCountries);
		// 	countryInstanceRef.current = new Dropdown(countryRef.current);
		// })
	}, [originalFilterList]);

	const applyFilter = () => {
		console.log(regionInstance, countryInstance);
		// const selectedRegions = regionInstanceRef.getSelection();
		// const selectedCountries = countryInstanceRef.getSelection();
		// const selectedProducts = productInstanceRef.getSelection();
		const selectedRegions = regionInstance!.getSelection();
		const selectedCountries = countryInstance!.getSelection();
		const selectedProducts = productInstance!.getSelection();
		setSelectedFilters({
			regions: selectedRegions,
			countries: selectedCountries,
			products: selectedProducts,
		})
		console.log(selectedRegions, selectedCountries, selectedProducts);

	}

	const resetFilter = () => {
		// regionInstanceRef.current.clearSelection();
		// countryInstanceRef.current.clearSelection();
		// productInstanceRef.current.clearSelection();
		regionInstance!.clearSelection();
		countryInstance!.clearSelection();
		productInstance!.clearSelection();

		setSelectedFilters(null)
	}

	useEffect(() => {
		console.log("New filters", selectedFilters);
		setReload(val => !val);
	}, [selectedFilters])


	const heading = `${current_level.toUpperCase()} Leader Board ${current_level !== "l3"
		? `- ${decodeHashUriComponent(
			urlParams[`l${parseInt(current_level[1]) - 1}_manager`]
		)}`
		: ""
		}`;

	return (
		<Fragment>
			<div className="dds__border dds__rounded dds__py-3 dds__mb-3 ">
				<div className="dds__px-4">
					<VulnerabilityHeaderInfo heading="Leader Vulnerability Summary" />
					<div className="dds__row dds__mb-1 ">
						<CardHeader />
					</div>
				</div>

				<div>
					<ServerTable
						columns={searchTableColumns}
						apiConfig={searchLeadersConfig}
						apiArgs={null}
						Icon={HeaderIcon}
						heading="Search"
						disableHeading={true}
						searchOnly={true}
						searchHeader="Search for L3 - L6 Leaders by full name"
						tableBorder={false}
					/>
				</div>
			</div>
			{isoriginalFilterListLoading && <LoadingSpinner />}
			{originalFilterList && <div id="leadertable" className="dds__w-100">
				<div
					style={{
						color: "var(--blue-800)",
						background: "var(--gray-100)",
					}}
					className="dds__w-100 dds__border dds__py-4"
				>
					<div className="dds__d-flex dds__flex-wrap dds__justify-content-between dds__px-4 dds__align-items-center">
						<div>
							<h4 className="dds__h4">{heading}</h4>
						</div>
						<div
							className="dds__d-flex dds__flex-wrap dds__align-items-end"
							style={{
								gap: 16,
							}}
						>
							<DropdownComponent
								label="Regions"
								elementRef={regionRef}
								selection="multiple"
								inputWidth="260px"
								PopupList={
									<ul
										className="dds__dropdown__list"
										role="listbox"
										tabIndex={-1}
									>
										{originalFilterList.regions.length > 0 && (
											<>
												{originalFilterList.regions.map(item => (
													<li
														key={item}
														className="dds__dropdown__item"
														role="none"
													>
														<button
															className="dds__dropdown__item-option"
															role="option"
															data-selected={selectedFilters != null && selectedFilters.regions.includes(item) ? "true" : "false"}
															tabIndex={-1}
														>
															<span className="dds__dropdown__item-label">
																{item}
															</span>
														</button>
													</li>
												))}
											</>
										)}
									</ul>
								}
							/>
							<DropdownComponent
								label="Countries"
								elementRef={countryRef}
								selection="multiple"
								inputWidth="260px"
								inputDisabled={originalFilterList.regions.length === 0}
								PopupList={
									<ul
										className="dds__dropdown__list"
										role="listbox"
										tabIndex={-1}
									>
										{originalFilterList.countries.length > 0 && (
											<>
												{originalFilterList.countries.map(item => (
													<li
														key={item}
														className="dds__dropdown__item"
														role="none"
													>
														<button
															className="dds__dropdown__item-option"
															role="option"
															data-selected={selectedFilters != null && selectedFilters.countries.includes(item) ? "true" : "false"}
															tabIndex={-1}
														>
															<span className="dds__dropdown__item-label">
																{item}
															</span>
														</button>
													</li>
												))}
											</>
										)}
									</ul>
								}
							/>
							<DropdownComponent
								label="Product"
								elementRef={productRef}
								inputWidth="260px"
								selection="multiple"
								PopupList={
									<ul
										className="dds__dropdown__list"
										role="listbox"
										tabIndex={-1}
									>
										{originalFilterList.products.length > 0 && (
											<>
												{originalFilterList.products.map(item => (
													<li
														key={item}
														className="dds__dropdown__item"
														role="none"
													>
														<button
															className="dds__dropdown__item-option"
															role="option"
															data-selected={selectedFilters != null && selectedFilters.products.includes(item) ? "true" : "false"}
															tabIndex={-1}
														>
															<span className="dds__dropdown__item-label">
																{item}
															</span>
														</button>
													</li>
												))}
											</>
										)}
									</ul>
								}
							/>
							<div>
								<button className="dds__button dds__button--sm dds__mr-2" onClick={applyFilter}>Apply</button>
								<button className="dds__button dds__button--secondary dds__button--sm" onClick={resetFilter}>Reset</button>
							</div>
						</div>

					</div>
				</div>

				<ServerTable
					apiArgs={[urlParams, selectedFilters === null ? originalFilterList : selectedFilters]}
					apiConfig={getLeaderBoardConfig}
					heading={heading}
					disableHeading
					Icon={Icon}
					columns={leaderTableColumns}
					searchHeader="Search by Name"
					disableColumnFunctions
					reload={reload}
					setReload={setReload}
				/>
			</div>}

			{productListVisible && (
				<Modal
					classNames=""
					onClose={() => {
						setProductListVisible(false);
					}}
				>
					<div
						className="dds__pt-3 dds__px-3"
						style={{
							position: "sticky",
							top: 0,
							background: "white",
							zIndex: 999,
						}}
					>
						<div className="dds__mb-2">
							<h3
								style={{
									color: "var(--blue-700)",
								}}
								className="dds__d-inline dds__h3 "
							>
								{productList ? productList.leader : "Products"}
							</h3>
							<IconButton
								style={{
									float: "right",
								}}
								onClick={() => setProductListVisible(false)}
							>
								<i
									style={{ fontSize: "1rem", color: "var(--gray-600)" }}
									className="dds__icon dds__icon--close-x"
								></i>
							</IconButton>
						</div>
						<div id="productModalSubheading">
							<p
								className=" dds__body-2--lead"
								style={{
									color: "var(--gray-600)",
								}}
							>
								Client Vulnerability Product Details
							</p>
						</div>
					</div>
					{productListLoading && !productListError && (
						<div className="dds__mt-3 dds__mx-auto dds__text-center">
							<LoadingSpinner size="md" />
						</div>
					)}
					{!productListLoading && !productListError && productList && (
						<div className="dds__px-3">
							<ClientTable
								data={productList?.records}
								columns={productListColumns}
								skipStateReset={false}
								activateExportAllData
								activateSearch
								activateFilters={false}
								disablePagination
								disableColumnFunctions
							/>
							{/* {productList?.records.map((val) => {
					return val.product + " " + val.cvlcount
				})} */}
						</div>
					)}
					{productListError && (
						<p className="dds__px-3">{productListErrorMessage}</p>
					)}
				</Modal>
			)}
		</Fragment>
	);
};

export default VulnerabilityLeaderboard;
