import ClientTable from '../../Common/ClientTable/ClientTable';
import DefaultCell from '../../Table/ReusableTableCells/DefaultCell';
import ActionColumn from "./DataModel/ActionColumn/ActionColumn";
const ExpandComponent = ({ row, columns, expandProps }) => {

    console.log("rowobject", row)
    console.log(columns)
    const SubData = [
        {
            telemetry_measures: row.values["telemetry_measures"],
            sub_measure: row.values["sub_measure"],
            low_threshold: row.values["low_threshold"],
            medium_threshold: row.values["medium_threshold"],
            high_threshold: row.values["high_threshold"],
            actions: row.values["actions"],
        }
    ];
    const SubColumns = [
        {
            Header: () => null, // No header
            id: '__id',
            Cell: DefaultCell,
            disableResizing: true,
            width: 50,
        },

        { Header: 'Telemetry Measures', accessor: 'telemetry_measures' },
        { Header: 'Sub Measure', accessor: 'sub_measure' },
        { Header: 'Low Threshold', accessor: 'low_threshold' },
        { Header: 'Medium Threshold', accessor: 'medium_threshold' },
        { Header: 'High Threshold', accessor: 'high_threshold' },
        {
            Header: 'Actions', accessor: 'actions', width: 70,
            Cell: ActionColumn,
        },

        // More SubColumns...
    ];


    return (
        <ClientTable
            data={SubData}
            columns={SubColumns}
            skipStateReset={false}
            disableToolbar={true}
            disableHeaderRow
        />

    )
}
export default ExpandComponent