import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form';

interface InputProps {
  register: UseFormRegister<FieldValues>;
  name: string;
  label: string;
  placeholder: string;
  errors: any;
  salt: string;
}

const InputTextArea: React.FC<InputProps> = ({
  register, name, label, placeholder, errors, salt
}) => {
  return (
    <div
      id="textarea-notes"
      className="dds__text-area__container"
      data-dds="text-area"
    >
      <div className="dds__text-area__header">
        <label
          id="text-area-label-notes"
          htmlFor="text-area-control-notes"
          className="dds__label dds__label--required"
        >
          {label}
        </label>
      </div>
      <div className="dds__text-area__wrapper">
        <textarea
          placeholder={placeholder}
          className="dds__text-area"
          // name="text-area-control-name-notes"
          id={`text-area-control-${name}-${salt}`}
          aria-required="true"
          aria-labelledby={`text-area-label-notes-${salt} text-area-helper-notes-${salt}`}
          required={true}
          {...register(name, { required: true })}
          style={{
            borderRadius: "14px",
          }}
        ></textarea>

        {/* <small id="text-area-helper-notes" className="dds__input-text__helper">Helper text</small>
                      <small id="text-area-error-notes" className="dds__invalid-feedback">Error text</small> */}
      </div>
      <small className="dds__invalid-feedback" style={{
        display: errors[name] ? 'block' : 'none'
      }}>This field is required</small>
    </div>
  )
}

export default InputTextArea