import { useContext } from 'react'
import ClientTable from '../../../Common/ClientTable/ClientTable'
import { ManageUserBlockedContext } from '../ManageUserBlockedContext'
import ManageUsersToolbar from '../ManageAdminTable/ManageUserToolbar'
import { columns } from './exportManageAdminColumns'

export const ManageTable = () => {
	const { filteredData } = useContext(ManageUserBlockedContext)

	console.log("filtered data", filteredData)
	return (
		<>
			<ManageUsersToolbar />
			<div className="dds__mt-4">
				<ClientTable
					data={filteredData}
					columns={columns}
					skipStateReset={false}
					disableToolbar
				// instanceProps={setAllData}
				/>
			</div>
		</>
	)
}
