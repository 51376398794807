import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { IconButton } from "../../../Table/TableStyles";
import { DisplayUserRoleButton } from "../ManageAdminTable/ManageUserRoleButtons";
import AdminModal from "../Modal/AdminModal";
import { SuccessMessageBar } from "../MessageBarManage";

const EditActionManageUser = ({ value, disabled }) => {
	// disabled = false;
	let modalInstanceRef = useRef<any>();
	const [show, setShow] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (show && modalInstanceRef.current) {
			modalInstanceRef.current.open();
		}
	}, [show]);

	return <>
		<IconButton
			className="dds__button dds__button--secondary"
			data-for="editTicketTip"
			data-tip="Edit"
			style={{ marginTop: 0, marginBottom: 0 }}
			onClick={() => { !disabled && setShow(true) }}
			aria-label="Edit admin access"
			disabled={disabled}
		>
			<i style={{ fontSize: '1.1rem' }} className="dds__icon dds__icon--pencil" aria-hidden="true" />
			<ReactTooltip id="editTicketTip" />

		</IconButton>
		{show && <AdminModal mode="edit" handleClose={() => setShow(false)} modalInstanceRef={modalInstanceRef} value={value} setShowSuccess={setShowSuccess} />}
		{showSuccess && <SuccessMessageBar mode={"edit"} />}

	</>
}
export default EditActionManageUser;