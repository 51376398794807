import { useEffect } from 'react';
import BlockUnblockPageHelper, { Category } from './BlockUnblockPageHelper/BlockUnblockPageHelper'
import { UnauthorizedError } from '../../../ErrorStates';
import { useUserInfo } from '../../../store/auth/UserInfoContext';
import { quarantine_portal } from '../../Dashboardv3/constants';

const BlockPage = () => {
	useEffect(() => {
		document.title = "Quarantine Portal | Block Devices";
	}, []);
	const user = useUserInfo().getUserRole(quarantine_portal);
	const userHasBlockAccess = useUserInfo().getUserInfo().userRole.blockDevices.block;
	useEffect(() => {
		if (!user.admin || !userHasBlockAccess)
			throw new UnauthorizedError()
	}, [])

	return (
		<BlockUnblockPageHelper category={Category.block} />
	)
}

export default BlockPage