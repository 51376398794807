import { useCustomMutation } from "../../../hooks/useCustomQuery";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export type UnblockDeviceBodyType = {
	hostName: string;
	deviceID: string;
	serviceTag: string;
	empName: string;
	empEmail: string;
	reasonOfUnblocking: string;
	notes: string;
};

const unblockDeviceConfig = () => ({
	url: `${baseUrl}/api/Add/v1/UnblockDeviceIndividual`,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
const useUnblockDeviceAPI = (onSuccess, onError) => {
	return useCustomMutation(unblockDeviceConfig(), {
		onSuccess,
		onError,
	});
};

export default useUnblockDeviceAPI;
