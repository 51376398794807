import { MessageBar } from '@dds/components';
import { useEffect, useRef } from 'react';

type propType = {
    heading?: string;
    message: string;
    useCase?: "warning"|"error"|"success";
    dataTimer?:number;
    center?: true;
    children?: any;
    style?: any;
};

const MessageBarComponent = (props:propType) => {
    // const element = document.getElementById("messagebar-"+props.id);
	const messageBarInstanceRef= useRef<any>(null);
    const messageBarRef = useRef<HTMLDivElement>(null);
	// messageBarInstanceRef.current = new MessageBar(messageBarRef.current);

    useEffect(() => {
        if (messageBarRef.current === null) return;
		messageBarInstanceRef.current = new MessageBar(
			messageBarRef.current
		);
		return () => {
			messageBarInstanceRef.current.dispose()
		}
	}, [messageBarRef]);

    let icon = "info-cir";
    if (props.useCase){
        if (props.useCase === "warning") {
            icon = "notice";
        } else if (props.useCase === "error") {
            icon = "error";
        } else{
            icon = "check-cir";
        }
    }

    return (
        <div
            ref={messageBarRef}
            id={"messagebar-"+Math.random()}
            data-dds="message-bar"
            data-timer={props.dataTimer?"true":"false"}
            data-timer-duration={props.dataTimer?props.dataTimer:null}
            className={props.useCase? `dds__message-bar dds__message-bar--${props.useCase}` : "dds__message-bar"}
            role="dialog"
            aria-describedby="message-bar-content-862346666"
            aria-labelledby="message-bar-title-862346666"
            style={props.style?props.style:null}
            >
            <span className={`dds__icon dds__icon--alert-${icon} dds__message-bar__icon`} aria-hidden="false"></span>
            <div id="message-bar-content-862346666" className={props.center? "dds__message-bar__content dds__message-bar__content--center":"dds__message-bar__content"}>
                {props.heading?<b id="message-bar-title-862346666">{props.heading}</b>:null}
                {props.message}
                {props.children}
            </div>
            </div>
    );

};

export default MessageBarComponent;
