import React from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form';

interface InputProps {
	register: UseFormRegister<FieldValues>;
	name: string;
	label: string;
	placeholder: string;
	errors: any;
	salt: string;
}

const InputField: React.FC<InputProps> = ({ register, name, label, placeholder, errors, salt }) => (
	<div id={`text input-${name}-${salt}`} className="dds__input-text__container">
		<label id={`text-input-label-${name}-${salt}`} htmlFor={`text-input-control-${name}-${salt}`} className="dds__label">{label}</label>
		<div className="dds__input-text__wrapper">
			<input
				placeholder={placeholder}
				type="text"
				className="dds__input-text"
				// name={`text-input-control-name-${name}`}
				id={`text-input-control-${name}-${salt}`}
				required={false}
				aria-labelledby={`text-input-label-${name}-${salt}`}
				{...register(name, { required: false })}
				style={{
					borderRadius: "14px",
				}}
				disabled
			/>
			<small className="dds__invalid-feedback" style={{
				display: errors[name] ? 'block' : 'none'
			}}>This field is required</small>
		</div>
	</div>
);

export default InputField;