import React from 'react';
import DropdownComponent from '../../../Common/DropdownComponent';

interface ActionTakenDropdownProps {
	statusRef: React.MutableRefObject<any>; // replace with appropriate type
	statusList: string[];
}

const ActionTakenDropdown: React.FC<ActionTakenDropdownProps> = ({ statusRef, statusList }) => (
	<DropdownComponent
		elementRef={statusRef}
		PopupList={
			<ul
				className="dds__dropdown__list"
				role="listbox"
				tabIndex={-1}
			>
				{statusList.map((status, index) => (
					<li key={index} className="dds__dropdown__item" role="none">
						<button
							type="button"
							className="dds__dropdown__item-option"
							role="option"
							data-selected="false"
							tabIndex={-1}
						>
							<span className="dds__dropdown__item-label">
								{status.charAt(0).toUpperCase() + status.slice(1)}
							</span>
						</button>
					</li>
				))}

			</ul>
		}
		label="Action Taken"
	/>
);

export default ActionTakenDropdown;