// FilterIcon.tsx
import React from 'react';

const FilterIcon: React.FC = () => (
	<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'var(--blue-600)' }}>
		<i
			className="dds__icon dds__icon--filter"
			aria-hidden="true"
			style={{
				fontSize: "1.5rem",
			}}
		/>
	</div>
);
export default FilterIcon;