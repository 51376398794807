import React from 'react';
import { ButtonWrapper } from './Styles';

interface FilterButtonsProps {
	applyHandler: () => void;
	resetHandler: () => void;
	tableLoading: boolean;
}

const FilterButtons: React.FC<FilterButtonsProps> = ({ applyHandler, resetHandler, tableLoading }) => (
	<ButtonWrapper>
		<div className="dds__d-flex dds__mt-4 dds__flex-wrap dds__justify-content-center">
			<div className="dds__mx-2">
				<button
					onClick={applyHandler}
					role="button"
					className="dds__button dds__button--primary"
					disabled={tableLoading}
				>
					Apply
				</button>
			</div>
			<div>
				<button
					role="button"
					className="dds__button dds__button--secondary"
					onClick={resetHandler}
					disabled={tableLoading}
				>
					Reset
				</button>
			</div>
		</div>
	</ButtonWrapper>
);

export default FilterButtons;