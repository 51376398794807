import React, { useEffect } from 'react';
import BlockUnblockPageHelper, { Category } from './BlockUnblockPageHelper/BlockUnblockPageHelper';
import { UnauthorizedError } from '../../../ErrorStates';
import { useUserInfo } from '../../../store/auth/UserInfoContext';
import { quarantine_portal } from '../../Dashboardv3/constants';

const UnblockPage: React.FC = () => {
    useEffect(() => {
        document.title = "Quarantine Portal | Unblock Devices";
    }, []);
    const user = useUserInfo().getUserRole(quarantine_portal);
    const userHasUnBlockAccess = useUserInfo().getUserInfo().userRole.blockDevices.unblock;
    useEffect(() => {
        if (!user.admin || !userHasUnBlockAccess)
            throw new UnauthorizedError()
    }, [])

    return (
        <BlockUnblockPageHelper category={Category.unblock} />
    );
};
export default UnblockPage;