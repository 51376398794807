import DefaultLayout from "../../Layouts/DefaultLayout";
import SummaryPortal from "../../components/SummaryPortal/SummaryPortal";
import VulnerabilityPortal from "../../components/VulnerabilityPortal/VulnerabilityPortal";
import AssetTrackerUser from "../../components/AssetTracker/AssetTrackerUser";
import CvlLayout from "../../Layouts/CvlLayout";
import VulnerabilityPortalUser from "../../components/VulnerabilityPortal/VulnerabilityPortalUser";
import ManageAdminWrapper from "../../components/VulnerabilityPortal/Manage Admin/ManageAdminWrapper";
import AssetTrackerAdmin from "../../components/AssetTracker/Admin/AssetTrackerAdmin";
import AssetRequestTrackerLayout from "../../Layouts/AssetRequestTrackerLayout";
import ManageUsersPage from "../../components/AssetTracker/ManageUsers/ManageUsersPage";
import Dashboardv3 from "../../components/Dashboardv3/DashboardPage/Dashboardv3";
import Dashboardv3Layout from "../../Layouts/Dashboardv3Layout";
import BlockedDevices from "../../components/VulnerabilityPortal/BlockedDevicesPage/BlockedDevices";

const routes = [
	{
		path: "/",
		exact: true,
		layout: Dashboardv3Layout,
		name: "Dashboardv3",
		component: Dashboardv3,
	},
	{
		path: "/request-tracker",
		layout: AssetRequestTrackerLayout,
		exact: true,
		name: "Request Tracker",
		component: AssetTrackerUser,
	},
	{
		path: "/request-tracker/admin",
		layout: AssetRequestTrackerLayout,
		exact: true,
		name: "Admin",
		component: AssetTrackerAdmin,
	},
	{
		path: "/request-tracker/manageusers",
		layout: AssetRequestTrackerLayout,
		exact: true,
		name: "Manage Users",
		component: ManageUsersPage,
	},
	{
		path: "/summary-portal",
		layout: DefaultLayout,
		exact: true,
		name: "Summary Portal",
		component: SummaryPortal,
	},
	{
		path: "/vulnerability-portal",
		layout: CvlLayout,
		exact: true,
		name: "Vulnerability Portal",
		component: VulnerabilityPortalUser,
	},
	{
		path: "/vulnerability-portal/admin",
		layout: CvlLayout,
		exact: true,
		name: "Administrator",
		component: VulnerabilityPortal,
	},
	{
		path: "/vulnerability-portal/manageusers",
		layout: CvlLayout,
		exact: true,
		name: "Manage Users",
		component: ManageAdminWrapper,
	},
	{
		path: "/vulnerability-portal/blockeddevices",
		layout: CvlLayout,
		exact: true,
		name: "Blocked Devices",
		component: BlockedDevices,
	},
];
export default routes;
