import ClientTable from "../../Common/ClientTable/ClientTable";
import columns from "../DataModel";
import { useMyAssetsAPI } from "./MyAssetContext";

const MyAssetTable = () => {
	const { data } = useMyAssetsAPI();
	return (
		<div
			style={{
				marginTop: 10,
			}}
		>
			<ClientTable
				data={data}
				columns={columns}
				skipStateReset={false}
				activateFilters={false}
				activateExportAllData={true}
				activateExportCurrentView={false}
				activateSearch
				defaultComplex={false}
			/>
		</div>
	)
}

export default MyAssetTable