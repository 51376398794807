import { DDSButton } from "@dds/react";
import MessageBarComponent from "../../Common/MessageBarComponent";
import { useContext, useEffect } from "react";
import { deleteAdminsBlockedConfig } from "./API";
import { useCustomMutation } from "../../../hooks/useCustomQuery";
import { ManageUserBlockedContext } from "./ManageUserBlockedContext";
import  LoadingSpinner  from "../../Common/LoadingSpinner";

export const WarningMessageBar: React.FC<{EmployeeName : string,EmployeeEmail:string,closeHandler: any }> = ({EmployeeName,EmployeeEmail,closeHandler}) => {
    const id = `overlay-${Math.random()}`;
    const {refetch} = useContext(ManageUserBlockedContext);
    const onCloseHandler = () => {
		const overlayElement = document.getElementById(id);
            if (overlayElement) {
                overlayElement.setAttribute("aria-hidden", "true");
            }
	}

    const { isLoading: isDeleting, mutate: deleteRecord } = useCustomMutation(deleteAdminsBlockedConfig(EmployeeEmail), {
        onSuccess: ()=>{closeHandler();refetch();},
        // onError: deleteErrorHandler
    });

    useEffect(() => {
        addEventListener('ddsMessageBarHiddenEvent', onCloseHandler)
        return () => {
        removeEventListener('ddsMessageBarHiddenEvent', onCloseHandler);
        }
    }, [id]);
    return(
        <div id={id} className="dds__overlay" aria-hidden="false">
            <MessageBarComponent message="Are you sure you want to delete this record:" heading="Warning: " useCase="warning" center={true} style={{position: "absolute", top: "32%", width: "36%",left: "32%",padding:"20px"}}>
                <div className="dds__body-2 dds__m-2">
                    Employee Name : <strong className="dds__body-2">{EmployeeName}</strong>
                </div>
                <div className='dds__d-flex' style={{gap:'16px',flexWrap:'wrap',justifyContent:'center'}}>
                    <DDSButton kind="secondary" size="md" onClick={closeHandler}> Cancel </DDSButton>
                    <DDSButton kind="primary" size="md" onClick={()=>{deleteRecord({})}} disabled={isDeleting}> 
                        {isDeleting? <LoadingSpinner size="sm" /> : "Yes"}
                    </DDSButton>
                </div>
            </MessageBarComponent>
        </div>
    );
};

export const SuccessMessageBar = ({mode}) => {
    const id = `overlay-${Math.random()}`;
    const onCloseHandler = () => {
		const overlayElement = document.getElementById(id);
            if (overlayElement) {
                overlayElement.setAttribute("aria-hidden", "true");
            }
	}
    useEffect(() => {
        addEventListener('ddsMessageBarHiddenEvent', onCloseHandler)
        return () => {
        removeEventListener('ddsMessageBarHiddenEvent', onCloseHandler);
        }
    }, [id]);
    let message = ""
    if (mode === "edit"){
        message = "User Updated Successfully";
    } else{
        message = "User Created Successfully";
    }
    return(
        <div id={id} className="dds__overlay" aria-hidden="false">
            <MessageBarComponent  message={message} useCase="success" dataTimer={2} style={{position: "absolute", top: "18%", width: "36%",left: "32%"}} />
        </div>
    );
};