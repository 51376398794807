import FilterChip from './FilterChip';

type DropdownVariable<T> = {
	controller: any,
	value: T,
	length: number
}

const createDropdownVariable = <T extends string | string[]>(dropdownList: any[], index: number): DropdownVariable<T> => {
	return {
		controller: dropdownList[index],
		value: dropdownList[index].getSelection(),
		length: dropdownList[index].getSelection().length > 0 ? 1 : 0
	};
}

const AppliedFilterBar = ({ dropdownList, resetFilters }) => {
	const dropdownVariables = dropdownList.map((_, index) => createDropdownVariable(dropdownList, index));

	const length = dropdownVariables.reduce((total, current) => total + current.length, 0);

	return (
		<div>
			<span>
				Filter Result({length})
			</span>
			{dropdownVariables.map((dropdownVariable, index) =>
				dropdownVariable.length > 0 &&
				<FilterChip key={index} listItem={Array.isArray(dropdownVariable.value) ? dropdownVariable.value : [dropdownVariable.value]} controller={dropdownVariable.controller} />
			)}
			{length > 0 &&
				<button
					onClick={resetFilters}
					className="dds__button dds__button--tertiary dds__button--sm">
					Reset
				</button>}
		</div>
	)
}

export default AppliedFilterBar;