import { DDSButton, DDSModal } from '@dds/react';
import { useCallback, useState } from 'react'
import ServerTable from '../../../../Common/ServerTable/ServerTable';
import { fetchAllVulnerabilityRecordsConfig } from '../../../../VulnerabilityPortal/API';
import columns from './DataModel';
import { fetchBlockDevicesTotalLogsConfig, fetchBlockDevicesSuccessLogsConfig, fetchBlockDevicesFailureLogsConfig } from '../../../API';

type PropType = {
	row: any;
	value: any;
	heading: string;
	apiConfig: typeof fetchAllVulnerabilityRecordsConfig
}
const RecordDetailsModal = ({ row, value, heading, apiConfig }: PropType) => {
	const [isOpen, setIsOpen] = useState(false);
	const openModal = useCallback(() => setIsOpen(true), []);
	const closeModal = useCallback(() => setIsOpen(false), []);

	console.log("row", row.values);
	return (
		<div>
			<DDSButton onClick={openModal} kind={"tertiary"}>{value}</DDSButton>
			<DDSModal
				size={"lg"}
				onClose={closeModal}
				open={isOpen}
				title={heading + " - " + row.values.fileName}
				footer={<DDSButton onClick={closeModal}>Close</DDSButton>}
			>
				<ServerTable
					apiArgs={[row.values.fileID]}
					apiConfig={apiConfig}
					columns={columns}
					searchHeader="Search"
					heading={heading}
					tableBorder={false}
					disableHeading
					disableColumnFunctions
					disableToolbar={heading == "Failed" ? false : true}
				/>
			</DDSModal></div >

	)
}

export default RecordDetailsModal