import React, { useEffect } from 'react';
import DateRangePicker from '../../../Common/DateRangePicker/DateRangePicker';
import DropdownComponent from '../../../Common/DropdownComponent';
import { ButtonWrapper } from './Styles';
import FilterIcon from './FilterIcon';
import FilterButtons from './FilterButtons';
import ActionTakenDropdown from './ActionTakenDropdown';

interface FilterComponentProps {
	sdRef: React.MutableRefObject<any>; // replace with appropriate type
	edRef: React.MutableRefObject<any>; // replace with appropriate type
	statusRef: React.MutableRefObject<any>; // replace with appropriate type
	applyHandler: () => void;
	resetHandler: () => void;
	tableLoading: boolean;
	statusList: string[];
}

const FilterBar: React.FC<FilterComponentProps> = ({ sdRef, edRef, statusRef, applyHandler, resetHandler, tableLoading, statusList }) => {
	return (
		<div className="dds__d-flex dds__w-100 dds__p-3 dds__align-items-center dds__flex-wrap dds__elevation-1" style={{
			background: "var(--gray-100)",
			borderRadius: "24px",
		}}>

			<div className="dds__d-flex dds__flex-wrap dds__justify-content-center" style={{
				gap: "1rem"
			}}>
				<FilterIcon />
				{statusList && <ActionTakenDropdown statusRef={statusRef} statusList={statusList} />}
				<div className='dds__d-flex' style={{
					gap: "1rem"
				}}>
					<DateRangePicker sdRef={sdRef} edRef={edRef} />
				</div>
			</div>
			<FilterButtons applyHandler={applyHandler} resetHandler={resetHandler} tableLoading={tableLoading} />
		</div>
	);
};

export default FilterBar;