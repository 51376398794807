import ReactTooltip from "react-tooltip";
import { fetchSupportDocConfig, fetchRefreshDateConfig } from "../API";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import useRefreshDateApi from "../useRefreshDateApi";

const VulnerabilityHeaderInfo: React.FC<{
	heading: string,
}> = ({ heading }) => {

	const { isLoading: supportDocLoading, error: supportDocError, data: supportDocs } =
		useCustomQuery(
			[queryKeys.cvlsupport],
			fetchSupportDocConfig());

	const { isLoading: refreshDateLoading, error: refreshDateError, data: refreshDate } =
		useRefreshDateApi();

	return <div className="dds__d-flex dds__justify-content-between dds__flex-wrap  dds__mb-4">
		<h4 className=" dds__h4 dds__font-weight-normal dds__d-inline" style={{
			color: 'var(--blue-800)'
		}}>
			{heading + " "}
		</h4>

		<div className="dds__d-flex dds__flex-wrap dds__justify-content-around">
			{!refreshDateLoading && !refreshDateError && <div className="dds__py-2 dds__pr-3">
				<span className="dds__text-muted dds__font-weight-normal dds__d-inline-block dds__align-self-center">
					Last updated on {refreshDate}
				</span>
			</div>}

			{!supportDocLoading && !supportDocError && <div className="dds__py-2 dds__d-flex" style={{
				gap: "1rem"
			}}>
				<div>
					<a rel="noreferrer" href={supportDocs.faq_link} target="_blank" style={{ textDecoration: 'none' }}>
						<i
							style={{ fontSize: "1.2rem" }}
							className="dds__icon faqicon dds__pl-2 dds__d-inline-block dds__align-self-center"
							data-for="faq2_tip"
							data-tip="FAQ"
						/>
						<span className='dds__sr-only'>Open FAQ</span>
						<ReactTooltip id="faq2_tip" />
					</a>
				</div>
				<div>
					<a rel="noreferrer" href={supportDocs.support_link} target="_blank" style={{ textDecoration: 'none' }}>
						<i
							style={{ fontSize: "1.2rem" }}
							className="dds__icon dds__icon--help-cir dds__pl-2  dds__d-inline-block dds__align-self-center"
							data-for="support2_tip"
							data-tip="Contact Support"
						/>
						<span className='dds__sr-only'>Contact support</span>
						<ReactTooltip id="support2_tip" />
					</a>
				</div>
			</div>}
		</div>

		{/* </div > */}
	</div>

};

export default VulnerabilityHeaderInfo;

