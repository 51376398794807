import { objectToQuery } from "../../utils/querycreator";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const pcfBaseUrl = process.env.REACT_APP_BASE_URL_PCF;

export const fetchRefreshDateConfig = (): object => ({
	url: baseUrl + "/api/Fetch/v1/RefreshDate",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const addMultipleVulnerabilityRecords = "/api/Add/v1/AllRecords";
export const addMultipleVulnerabilityRecordsConfig = (
	token: string,
	data: any,
	isLargeFile: boolean,
	position: number,
	serialNumber: number
): object => ({
	url:
		baseUrl +
		addMultipleVulnerabilityRecords +
		`?isLargeFile=${isLargeFile}&position=${position}&serialNumber=${serialNumber}`,
	method: "POST",
	headers: {
		jwt_token: `${token}`,
		"Content-Type": "application/json",
		apikey: process.env.REACT_APP_L7_APIKEY,
	},
	body: data,
});

// const fetchVulnerabilityRecords = "/api/Fetch/v1/Records";
// export const fetchVulnerabilityRecordsConfig = (token: string):object => ({
// 		url: baseUrl + fetchVulnerabilityRecords,
// 		method: "GET",
// 		headers: {
// 			Authorization: `${token}`,
// 			"Content-Type": "application/json",
// 		},
// 	});

export const fetchVulnerabilityRecordsConfig = (params: {
	page?: number;
	size?: number;
	q?: string;
	exportCsv?: boolean;
	sort: string;
}): object => ({
	url: `${baseUrl}/api/Fetch/v1/Records?${objectToQuery(params)}`,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

// const fetchAllVulnerabilityRecords = "/api/Fetch/v1/AllRecordsByAdmin";
// export const fetchAllVulnerabilityRecordsConfig = (token: string):object => ({
// 		url: baseUrl + fetchAllVulnerabilityRecords,
// 		method: "GET",
// 		headers: {
// 			Authorization: `${token}`,
// 			"Content-Type": "application/json",
// 		},
// 	})

export const fetchAllVulnerabilityRecordsConfig = (params: {
	page?: number;
	size?: number;
	q?: string;
	exportCsv?: boolean;
	sort: string;
}): object => ({
	url: `${baseUrl}/api/Fetch/v1/AllRecordsByAdmin?${objectToQuery(params)}`,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

// const fetchVulnerabilityRecordsBySearch = "/api/Fetch/v1/SearchRecord";
// export const fetchVulnerabilityRecordsBySearchConfig = (
// 	token: string,
// 	data: {
// 		reference: string;
// 	}
// ):object => ({
// 		url: baseUrl + fetchVulnerabilityRecordsBySearch,
// 		method: "POST",
// 		headers: {
// 			Authorization: `${token}`,
// 			"Content-Type": "application/json",
// 		},
// 		body: data,
// 	});

export const fetchVulnerabilityRecordsBySearchConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	body: {
		reference: string;
	}
): object => ({
	url: `${baseUrl}/api/Fetch/v1/AllRecordsByAdmin?${objectToQuery({
		...params,
		q: body.reference,
	})}`, // `${baseUrl  }/api/Fetch/v1/SearchRecord?${objectToQuery(params)}`,
	method: "GET", // "POST",
	headers: {
		"Content-Type": "application/json",
	},
	// body,
});

const fetchVulnerabilityLandingPageRecords = "/api/Fetch/v1/RecordsDashboard";
export const fetchVulnerabilityLandingPageRecordsConfig = (): object => ({
	url: baseUrl + fetchVulnerabilityLandingPageRecords,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const fetchSupportDoc = "/api/Fetch/v1/SupportDoc";
export const fetchSupportDocConfig = (): object => ({
	url: baseUrl + fetchSupportDoc,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const fetchCommentsConfig = (
	token: string,
	body: {
		assethostname: string;
		email: string;
		product: string;
		servicetag: string;
		username: string;
	}
): object => {
	const endpoint = "/api/Fetch/v1/Comments";
	return {
		url: baseUrl + endpoint,
		method: "POST",
		headers: {
			Authorization: `${token}`,
			"Content-Type": "application/json",
		},
		body,
	};
};

export const addUserCommentConfig = (
	token: string,
	body: {
		assethostname: string;
		email: string;
		product: string;
		servicetag: string;
		username: string;
		message: string;
	}
): object => {
	const endpoint = "/api/Add/v1/CommentByUser";
	return {
		url: baseUrl + endpoint,
		method: "POST",
		headers: {
			Authorization: `${token}`,
			"Content-Type": "application/json",
		},
		body,
	};
};

export const addAdminCommentConfig = (
	token: string,
	body: {
		assethostname: string;
		email: string;
		product: string;
		servicetag: string;
		username: string;
		message: string;
	}
): object => {
	const endpoint = "/api/Add/v1/CommentByAdmin";
	return {
		url: baseUrl + endpoint,
		method: "POST",
		headers: {
			Authorization: `${token}`,
			"Content-Type": "application/json",
		},
		body,
	};
};

export const addSnowTicketConfig = (
	token: string,
	body: {
		assethostname: string;
		email: string;
		product: string;
		servicetag: string;
		username: string;
		ticket: string;
	}
): object => {
	const endpoint = "/api/Add/v1/SnowTicket";
	return {
		url: baseUrl + endpoint,
		method: "POST",
		headers: {
			jwt_token: `${token}`,
			"Content-Type": "application/json",
			apikey: process.env.REACT_APP_L7_APIKEY,
		},
		body,
	};
};

export const fetchAdminListConfig = (): object => {
	const endpoint = "/api/Fetch/v1/Admins";
	return {
		url: baseUrl + endpoint,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const assignAdminConfig = (
	token: string,
	body: {
		assethostname: string;
		email: string;
		product: string;
		servicetag: string;
		username: string;
		assign_by_ntid: string;
		assign_to_name: string;
		assign_to_ntid: string;
	}
): object => {
	const endpoint = "/api/Add/v1/AssignAdmin";
	return {
		url: baseUrl + endpoint,
		method: "POST",
		headers: {
			jwt_token: `${token}`,
			"Content-Type": "application/json",
			apikey: process.env.REACT_APP_L7_APIKEY,
		},
		body,
	};
};

// export const addBlockedDevicesConfig = (): object => ({
// 	url: baseUrl + "/api/Add/v1/AddBlockedDevices",
// 	method: "POST",
// 	headers: {
// 		"Content-Type": "application/json",
// 	},
// });

export const addBlockedDevicesConfig = (): object => ({
	url: baseUrl + "/api/Add/v1/AddBlockedDevices",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});

export const fetchBlockedDevicesConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	filters: {
		endDate: string;
		isDateRange: boolean;
		startDate: string;
	}
) => {
	let endpoint = "/api/Fetch/v1/FetchAllBlockedDevices";
	let headers = {
		"Content-Type": "application/json",
	};
	if (params.exportCsv === true) headers["responseType"] = "blob";

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params),
		method: "POST",
		headers: headers,
		body: filters,
	};
};
