import React, { useEffect, useState, useRef, useContext } from "react";
import ClientTable from "../../Common/ClientTable/ClientTable";
import { displayValue } from "@tanstack/react-query-devtools/build/lib/utils";
import "../../Table/TableStyles.tsx";
import ExpandComponent from "./ExpandComponent";
import { TableContext } from './TableContext'
import TelemetryMeasuresToolbar from "./Toolbar/Toolbar";


//import "../../Table/Table"
//  import ActionColumn from '../ManageUsers/DataModel/ActionColumn/ActionColumn';
// const ExpandComponent = ({ row, columns, expandProps }) => {

//     console.log("rowobject",row)
//     console.log(columns)
//     const SubData=[
//         { telemetry_measures: row.values["telemetry_measures"],
//         sub_measure: row.values["sub_measure"],
//         low_threshold: row.values["low_threshold"],
//         medium_threshold: row.values["medium_threshold"],
//         high_threshold: row.values["high_threshold"],
//         actions: row.values["actions"],}
//     ];
//     const SubColumns = [
//         {
//             Header: () => null, // No header
//             id:'__id',
//             Cell: DefaultCell,
//             disableResizing: true,
//             width: 50,
//         },

//         { Header: 'Telemetry Measures', accessor: 'telemetry_measures' },
//         { Header: 'Sub Measure', accessor: 'sub_measure' },
//         { Header: 'Low Threshold', accessor: 'low_threshold' },
//         { Header: 'Medium Threshold', accessor: 'medium_threshold' },
//         { Header: 'High Threshold', accessor: 'high_threshold' },
//         { Header: 'Actions', accessor: 'actions' },

//         // More SubColumns...
//     ];

//     return(
//         <ClientTable
//         data={SubData}
//         columns={SubColumns}
//         skipStateReset={"2"}
//         disableToolbar={true}
//         disableHeaderRow
//        />

//     )
// }

// export const TelemetryMeasuresTable = ({ columns, AddMeasuresButton }) => {
// const tableRef = useRef(null)
// const [tableData, setTableData] = useState([]);
// const data = [
// {
// 	telemetry_measures: "Usage",
// 	sub_measure: "HOURS_ON",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: false,
// },
// {
// 	telemetry_measures: "CPU",
// 	sub_measure: "AVERAGE_PQL",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "CPU",
// 	sub_measure: "AVERAGE_THREADCOUNT",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "CPU",
// 	sub_measure: "AVG_CPU_CONSUMPTION",
// 	low_threshold: "<25% ",
// 	medium_threshold: " ",
// 	high_threshold: ">70% ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "CPU",
// 	sub_measure: "CPU_80_100_PCT",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Memory",
// 	sub_measure: "AVG Memory Consumption",
// 	low_threshold: "<50% ",
// 	medium_threshold: " ",
// 	high_threshold: ">70%",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Memory",
// 	sub_measure: "PGS_PER_SEC_PCT",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Memory",
// 	sub_measure: "AVAILMEM_0_1024MB_PCT",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Memory",
// 	sub_measure: "AVG Memory Available",
// 	low_threshold: ">50%",
// 	medium_threshold: " ",
// 	high_threshold: "<3GB",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "???",
// 	sub_measure: "C0_STATE_PCT",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Thermal",
// 	sub_measure: "Ambient_THERMISTOR_AVG_TEMP",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Thermal",
// 	sub_measure: "CPU_THERMISTOR_AVG_TEMP",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Thermal",
// 	sub_measure: "NBFAN_FAN_DUTY_CYCLE_PCT",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Thermal",
// 	sub_measure: "NBFAN_AVERAGE_FAN_RPM",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Disk I/O",
// 	sub_measure: "DISK0_READ_TIME_PCT",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Disk I/O",
// 	sub_measure: "Free Space %",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "GPU",
// 	sub_measure: "GPU % Used",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "GPU",
// 	sub_measure: "Memory Consumption",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Network",
// 	sub_measure: "Wi-Fi Signal strength",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Network",
// 	sub_measure: "Dropped Pagage Rate",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// 	// expandData: true,
// },
// {
// 	telemetry_measures: "Power",
// 	sub_measure: "Power Plan",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// },
// {
// 	telemetry_measures: "Battery/Power",
// 	sub_measure: "Watt Consumption Average",
// 	low_threshold: " ",
// 	medium_threshold: " ",
// 	high_threshold: " ",
// 	actions: null,
// },

// More data...
// ];

// const columns = [
//     {
//         // Expander Cell
//         Header: () => null, // No header
//         id: "expander",
//         Cell: ({ row }: UseTableCellProps<any>) => (
//             <span {...row.getToggleRowExpandedProps()}>
//                 {row.isExpanded ? (
//                     <IconButtonWrapper aria-label='Collapse row' role={'button'} className="dds__button dds__button--secondary">
//                         <i className={` dds__icon dds__icon--chevron-up`} />
//                     </IconButtonWrapper>
//                 ) : (
//                     <IconButtonWrapper aria-label='Expand row' role={'button'} className="dds__button dds__button--secondary">
//                         <i className={` dds__icon dds__icon--chevron-down`}/>
//                     </IconButtonWrapper>
//                 )}
//             </span>
//         ),
//         disableResizing: true,
//         width: 50,
//     },
//     { Header: 'Telemetry Measures', accessor: 'telemetry_measures' },
//     { Header: 'Sub Measure', accessor: 'sub_measure' },
//     { Header: 'Low Threshold', accessor: 'low_threshold' },
//     { Header: 'Medium Threshold', accessor: 'medium_threshold' },
//     { Header: 'High Threshold', accessor: 'high_threshold' },
//     {
//         Header: "Action",
//         accessor: "action",
//         width: 70,
//         Cell: ActionColumn,
//     },

//     // More columns...
// ];



export const TelemetryMeasuresTable = ({ columns, AddMeasuresButton }) => {
	const { allData } = useContext(TableContext); // Access allData from TableContext

	return (
		<>
			<TelemetryMeasuresToolbar AddMeasuresButton={AddMeasuresButton} />
			<div className="dds__mt-4">
				<ClientTable
					data={allData}
					columns={columns}
					skipStateReset={false} // Adjust as per your requirement
					activateSearch // Enable search functionality if supported by ClientTable
					disableColumnFunctions // Disable column functions if applicable
					disablePagination // Disable pagination if applicable
					canExpand // Enable row expansion if applicable
					ExpandComponent={ExpandComponent} // Component for row expansion
					expandProps={{ randomState: 'randomValue' }} // Props for ExpandComponent
				/>
			</div>
		</>
	);
};

export default TelemetryMeasuresTable;
