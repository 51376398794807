import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { matchSorter } from "match-sorter"
import { APIRequestFailedError, AuthError } from "../../../ErrorStates";
import {columns} from "../Manage Users/ManageAdminTable/exportManageAdminColumns";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import LoadingSpinner from "../../Common/LoadingSpinner";


export const ManageUserBlockedContext = createContext<any>(null);

export const ManageUserBlockedContextProvider = ({ apiConfig, children }) => {
	// Change to API format, once API gets fixed with required data values

	// type dataType = {
	// 	employeeName: string;
	// 	asset_number: string;
	// 	emailId: string;
	// 	country: string;
	// 	stockroom: string;
	// 	role: string;
	// 	status: string;
	// 	action: null;
	// }[]
	console.log("apiConfig", apiConfig);
    //DeviceTODO: Change the config to device Management
	const { isLoading, error: fetchError, data: allData,refetch } = useCustomQuery([apiConfig.keyName], apiConfig.getAdminsConfig(),
		{
			select: (data) => {
                console.log("data", data)
				//DeviceTODO: Change the data to device Management
				// data.admins = [{"admname":"Romy","email":"jyockley0@flickr.com","ntid":"Jenilee","permission":{"Create":false,"Edit":false,"Block":true,"Unblock":true},"rolename":"Admin"},
				// {"admname":"Pincus","email":"wmcgaw1@washington.edu","ntid":"Waylin","permission":{"Create":true,"Edit":true,"Block":true,"Unblock":false},"rolename":"Admin"},
				// {"admname":"Edna","email":"ptebbitt2@youtu.be","ntid":"Petronille","permission":{"Create":false,"Edit":false,"Block":false,"Unblock":true},"rolename":"SuperAdmin"},
				// {"admname":"Ive","email":"vbullin3@omniture.com","ntid":"Vin","permission":{"Create":true,"Edit":false,"Block":true,"Unblock":true},"rolename":"SuperAdmin"},
				// {"admname":"Matthiew","email":"tattersoll4@mtv.com","ntid":"Tamma","permission":{"Create":true,"Edit":true,"Block":true,"Unblock":false},"rolename":"Admin"},
				// {"admname":"Rozele","email":"gditts5@google.ca","ntid":"Gaye","permission":{"Create":false,"Edit":true,"Block":false,"Unblock":true},"rolename":"Admin"},
				// {"admname":"Rebbecca","email":"hharries6@washingtonpost.com","ntid":"Horten","permission":{"Create":true,"Edit":false,"Block":false,"Unblock":false},"rolename":"Admin"},
				// {"admname":"Leoine","email":"ccaukill7@bandcamp.com","ntid":"Caddric","permission":{"Create":true,"Edit":false,"Block":false,"Unblock":false},"rolename":"SuperAdmin"},
				// {"admname":"Zuzana","email":"elowle8@irs.gov","ntid":"Ethelind","permission":{"Create":true,"Edit":true,"Block":true,"Unblock":true},"rolename":"Admin"},
				// {"admname":"Trista","email":"mwisker9@163.com","ntid":"Merrielle","permission":{"Create":false,"Edit":true,"Block":true,"Unblock":false},"rolename":"SuperAdmin"}];
				return data.blockDeviceAdminList;
				// return dummyData
			}
		});
	// const [allData, setAllData] = useState<dataType>([]);
	const [error, setError] = useState<string>();
	const handleError = useErrorHandler();
	const [filteredData, setFilteredData] = useState<any>([])


	const filterData = (searchText: string) => {
		console.log("Filtering")
		if (searchText !== "") {
			setFilteredData(
				matchSorter(allData, searchText, {
					keys: columns.map((col) => {
						return col.accessor
					}),
				})
			)
		} else setFilteredData(allData)
	}

	useEffect(() => {
		// if (allData["records_found"] === 0) {
		// 	setError("No Record Found")
		// 	return
		// }
		if (allData)
			setFilteredData(allData);
	}, [allData])

	useEffect(() => {
		if (fetchError) {
			console.log("error on component", fetchError)
			if (fetchError instanceof APIRequestFailedError) {
				if (fetchError.statusCode === "201")
					setError("No Record Found")
				else if (fetchError.statusCode === "204")
					setError(fetchError.message)
				else
					handleError(fetchError)
			} else if (fetchError instanceof AuthError) {
				handleError(fetchError)
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				)
			}
		}
	}, [fetchError, handleError])


	return <ManageUserBlockedContext.Provider value={{ filteredData, allData, filterData,refetch }}>
		{(isLoading || error) && <div className="dds__mx-auto dds__text-center" style={{
			marginTop: "calc(100px + 1.5rem)"
		}}>
			{isLoading &&
				<LoadingSpinner label="Fetching Admins" />
			}
			{error && <>{error}</>}

		</div>}
		{!isLoading && !error && children}
	</ManageUserBlockedContext.Provider>
}