import QuarantinePortalLayout from "../../Layouts/QuarantinePortalLayout";
import BlockPage from "../../components/QuarantinePortal/BlockUnblockPage/BlockPage";
import BlockUnblockDevicesPage from "../../components/QuarantinePortal/BlockUnblockPage/BlockUnblockDevicesPage";
import BulkUploadLogsPage from "../../components/QuarantinePortal/BlockUnblockPage/BulkUploadLogs/BulkUploadLogsPage";
import UnblockPage from "../../components/QuarantinePortal/BlockUnblockPage/UnblockPage";
import CommandDetailsPage from "../../components/QuarantinePortal/BlockedDevicesPage/CommandDetailsPage";
import ManageUsersLayout from "../../components/QuarantinePortal/Manage Users/ManageUsersLayout";

const quarantinePortalRoutes = [
	// {
	// 	path: "/quarantine-portal",
	// 	exact: true,
	// 	layout: QuarantinePortalLayout,
	// 	name: "Quarantine Portal",
	// 	component: BlockUnblockDevicesPage,
	// },
	{
		path: "/quarantine-portal/block-devices",
		exact: true,
		layout: QuarantinePortalLayout,
		name: "Block Devices",
		component: BlockPage,
	},
	{
		path: "/quarantine-portal/unblock-devices",
		exact: true,
		layout: QuarantinePortalLayout,
		name: "Unblock Devices",
		component: UnblockPage,
	},
	{
		path: "/quarantine-portal/bulk-upload-logs",
		exact: true,
		layout: QuarantinePortalLayout,
		name: "Bulk Upload Logs",
		component: BulkUploadLogsPage,
	},
	{
		path: "/quarantine-portal/command-details",
		exact: true,
		layout: QuarantinePortalLayout,
		name: "Command Details",
		component: CommandDetailsPage,
	},
	{
		path: "/quarantine-portal/manage-users",
		exact: true,
		layout: QuarantinePortalLayout,
		name: "Manage Users",
		component: ManageUsersLayout,
	},
];
export default quarantinePortalRoutes;