import React from "react";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";

export const baseUrl = process.env.REACT_APP_BASE_URL;

type DeviceData = {
	hostname: string;
	serviceTag: string;
	empName: string;
	empEmail: string;
	deviceID: string;
	lastIpAddress: string;
	lastSeen: string;
	healthStatus: string;
	deviceObjectID: string;
	latestAction: string;
	machineID: string;
};
// Define the return type of useDeviceSearch
interface UseDeviceSearchResult {
	error: any;
	isError: boolean;
	isFetching: boolean;
	data: DeviceData | undefined;
	refetch: () => void;
}

const searchDeviceConfig = (searchInput: string) => ({
	url: `${baseUrl}/api/Fetch/v1/SearchBlockDevicesHostnames?searchValue=${searchInput}`,
	// url: `${baseUrl}/api/Fetch/v1/SupportDoc`,
	method: "GET",
});
const useDeviceSearch = (searchInput: string): UseDeviceSearchResult => {
	return useCustomQuery(
		[queryKeys.BDDeviceSearch, searchInput],
		searchDeviceConfig(searchInput),
		{
			select: (data: any) => {
				return data.blockDeviceAdmin;
				// let datat: DeviceData = {
				// 	hostname: "w10jzn2nl3.aus.amer.dell.com",
				// 	serviceTag: "JZN2NL3",
				// 	empName: "John Doe",
				// 	empEmail: "john.doe@delll.com",
				// 	deviceID: "eedb25ee27ff3a61583c4d527931f039f2bb85dc",
				// 	lastIpAddress: "124.2.2.1",
				// 	lastSeen: "2021-09-01T12:00:00Z",
				// 	healthStatus: "active"
				// }
				// return datat;
			},
			enabled: false,
		}
	);
};

export default useDeviceSearch;
