import React from 'react'
import ManageUserToolbar from './ManageAdminTable/ManageUserToolbar';
import { ManageUserBlockedContextProvider } from './ManageUserBlockedContext';
import { queryKeys } from '../../../react-query/constants';
import { getAdminsBlockedConfig } from './API';
import { ManageTable } from './ManageAdminTable/ManageTable';

const ManageUsersPage = () => {

	return (
		<ManageUserBlockedContextProvider apiConfig={{ keyName: queryKeys.blockedDeviceAdmin, getAdminsConfig: getAdminsBlockedConfig }}>
			<ManageTable/>
		</ManageUserBlockedContextProvider>
	);
}

export default ManageUsersPage