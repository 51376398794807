import React, { useEffect, useRef } from "react";
import { Accordion } from "@dds/components";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useSearch } from "../Search/useSearch";
import DDSSearch from "../Search/DDSSearch";
import useFuzzySearch from "../Search/useFuzzySearch";
import AccordianWrapper from "./Wrappers/AccordianWrapper";
import AccordianBodyWrapper from "./Wrappers/AccordianBodyWrapper";
import AccordianHeadingWrapper from "./Wrappers/AccordianHeadingWrapper";
import { cardColumns } from "../constants";
import { CardTableStyle } from "../CardTable/Styles";

interface IDDSAccordianProps {
    data: any[];
    isError: boolean;
    isLoading: boolean;
    columns: cardColumns;
    enableControls?: boolean;
    totalElements: number;
    labels?: {
        expandLabel: string;
        collapseLabel: string;
    };
    accordianID: string;
    sideWayTable?: boolean;
}

const DDSAccordian: React.FC<IDDSAccordianProps> = ({
    data,
    isError,
    isLoading,
    columns,
    enableControls = true,
    totalElements,
    labels = {
        expandLabel: "Expand All",
        collapseLabel: "Collapse All",
    },
    accordianID,
    sideWayTable = true,
}) => {
    const { searchText, setSearchText, searchRef } = useSearch();
    const filteredData = useFuzzySearch(data, searchText, columns);

    const accordianInstanceRef = useRef<any>(null);
    const accordianRef = useRef<HTMLDivElement>(null);

    const headerColumns = columns.filter(col => !col.hidden).filter(col => !col.visible);
    const bodyColumns = columns.filter(col => !col.hidden).filter(col => col.visible);

    useEffect(() => {
        if (filteredData.length === 0) return;

        accordianInstanceRef.current?.dispose?.();
        accordianInstanceRef.current = new Accordion(accordianRef.current);
        if (searchText.length === 0)
            accordianInstanceRef.current?.expand?.(0);
        () => {
            accordianInstanceRef.current?.dispose?.();
        };
    }, [searchText, filteredData]);

    if (isLoading)
        return (
            <div className="dds__mt-5">
                <LoadingSpinner size="xl" />
            </div>
        );

    return (
        <div>
            {!isError && (
                <>
                    {/* Search component */}
                    <DDSSearch searchRef={searchRef} searchText={searchText} setSearchText={setSearchText} />
                    {/* Accordian Wrapper */}
                    <AccordianWrapper
                        labels={labels}
                        totalElements={totalElements}
                        enableControls={enableControls && data.length > 0}
                        elementRef={accordianRef}
                    >
                        {filteredData.map((record, recordIndex) => {
                            const triggerId = `trigger-${accordianID}-item${recordIndex}`;
                            const contentId = `content-${accordianID}-item${recordIndex}`;
                            return (
                                <div
                                    className="dds__accordion__item"
                                    key={recordIndex}
                                >
                                    <AccordianHeadingWrapper alert={record['alert']} triggerId={triggerId} contentId={contentId}>
                                        {/* Extra div to counteract default space between property w/o modifying styles */}
                                        <div>
                                            {headerColumns
                                                .map((col, index, filteredColumns) => {
                                                    const CellComponent = col.Cell;
                                                    const value = record[col.accessor];
                                                    const separator =
                                                        index !== filteredColumns.length - 1 ? (
                                                            <span aria-hidden="true" className=" dds__mx-2"> | </span>
                                                        ) : (
                                                            ""
                                                        ); // Add separator only if it's not the last value
                                                    return (
                                                        <React.Fragment key={record[col.accessor]}>
                                                            <CellComponent
                                                                value={col.accessor === "shirCapable" && record[col.accessor] === "False" ? "" :
                                                                    col.accessor === "shirCapable" && record[col.accessor] === "True" ? "SHIR Enabled" : value}
                                                                row={record} />
                                                            {separator}
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </div>
                                    </AccordianHeadingWrapper>
                                    <AccordianBodyWrapper
                                        key={recordIndex}
                                        contentId={contentId}
                                        triggerId={triggerId}
                                    >
                                        <div

                                            className="dds__border dds__p-4"
                                            style={{
                                                backgroundColor: "var(--gray-100)",
                                            }}
                                        >
                                            {sideWayTable && <table className="dds__w-100">
                                                <tbody className="dds__container">
                                                    {bodyColumns
                                                        .map(col => (
                                                            <tr key={col.accessor} className="dds__row">
                                                                <th id={col.accessor} scope="row" key={col.Header} className="dds__col--2 dds__col--md-4" style={{ color: "#0063B8", textAlign: "left" }}>
                                                                    {col.Header}
                                                                </th>
                                                                <td headers={col.accessor} key={record[col.accessor]} className="dds__col--2 dds__col--md-8">
                                                                    <p className="dds__body-2" style={{ wordBreak: "break-all", color: "#0E0E0E" }}>
                                                                        {<col.Cell value={record[col.accessor]} row={record} />}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                            {!sideWayTable && <CardTableStyle>
                                                <thead>
                                                    <tr>
                                                        {bodyColumns
                                                            .map(col => (
                                                                <th id={col.accessor} scope="row" key={col.accessor} style={{ color: "#0063B8" }}>
                                                                    {col.Header}
                                                                </th>
                                                            ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        {bodyColumns
                                                            .map(col => (

                                                                <td headers={col.accessor} key={col.accessor} data-label={col.Header}>
                                                                    <p className="dds__body-2" style={{ wordBreak: "break-all", color: "#0E0E0E" }}>
                                                                        {<col.Cell value={record[col.accessor]} row={record} />}
                                                                    </p>
                                                                </td>
                                                            ))}
                                                    </tr>
                                                </tbody>
                                            </CardTableStyle>}
                                        </div>
                                    </AccordianBodyWrapper>

                                </div>
                            )
                        })}
                        {filteredData?.length === 0 && <p>No Matching Record Found</p>}
                    </AccordianWrapper>
                </>
            )}
        </div>
    );
};

export default DDSAccordian;
