import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { quarantine_portal, cardsTheme, req_tracker } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import { useState, useEffect } from 'react';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';
import Admin from './Admin';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';

const QuarantinePortalCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {

		const { isOrg } = useDashboardContext();

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("Manage Devices")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[quarantine_portal]) {
					setMessage(metaData.data[quarantine_portal]);
				}
			}
		}, [metaData, isOrg]);


		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.admin || !tabsMetaData.superAdmin,
			name: tabsMetaData.admin,
			iconClass: "dds__icon--user-group",
			content: Admin,
			active: !isOrg,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "/quarantine-portal/command-details",
					external: false,
					ariaLabel: "Open " + quarantine_portal + " page"
				}
			]
		}]

		const triggerID = createTrigger(quarantine_portal)
		return <Card
			themeColor={cardsTheme[quarantine_portal].themeColor}
			name={quarantine_portal}
			ddsIconClass={cardsTheme[quarantine_portal].ddsIconClass}
			isFav={favouriteStatus}
			idName={quarantine_portal}
			description={description}
			isLoading={false}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={quarantine_portal}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={quarantine_portal} tabsData={tabDetails} salt={quarantine_portal} />
			</DDSDrawer>

		</Card>


	}

export default QuarantinePortalCard;