import React, {
	useEffect,
	useState,
} from "react";
import { PageTitle } from "./styles";
import FilterCard from "./FilterCard/FilterCard";
import ServerTable from "../../Common/ServerTable/ServerTable";
import { mobileDevicesTableConfig } from "../API";
import columnValue from "../DataModel";
import { MobileDeviceFilterObject, useMobileDevicesLeaderFilters } from "./MobileDevicesOrgContext";
import LoadingSpinner from "../../Common/LoadingSpinner";
import Card from "../../MobileDevices/Org/StatisticCard/StatisticCard";

const MobileDevicesOrgPage: React.FC = () => {

	useEffect(() => {
		document.title = "Mobile Devices | My Organization"
	}, [])
	const {
		isLoadingFilters: isLoadingFilters,
		fetchError: filterFetchError,
	} = useMobileDevicesLeaderFilters();

	const [reload, setReload] = useState<boolean>(false);
	const [filter, setFilter] = useState<MobileDeviceFilterObject>();
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [tableError, setTableError] = useState<Error | null>(null)
	useEffect(() => {
		if (filter) {
			setReload(true);
		}
	}, [filter])

	return (
		<>
			<div className="dds__container-fluid dds__px-0">
				<PageTitle>Mobile Devices</PageTitle>
				{isLoadingFilters && !filterFetchError && (
					<div
						className="dds__mx-auto dds__text-center"
						style={{
							marginTop: 150,
						}}
					>
						<LoadingSpinner label="Loading Filters" />
					</div>
				)}
				{filterFetchError &&
					<div
						className="dds__mx-auto dds__text-center"
						style={{
							marginTop: 150,
						}}
					>
						<h4 className="dds_h4">Unknown error occurred while loading data filters. Please try again later.</h4>
					</div>
				}
				{!isLoadingFilters && !filterFetchError &&
					<>
						<FilterCard applyFilterObj={setFilter} loading={tableLoading} error={tableError} />
						{filter &&
							<>
								<Card
									selected={false}
									count={1}
									filters={[filter]}
								/>
								<ServerTable
									apiArgs={[filter]} // add filters
									apiConfig={mobileDevicesTableConfig}
									columns={columnValue}
									searchHeader="Search"
									heading="Mobile Devices records"
									tableBorder={false}
									disableHeading
									reload={reload}
									setReload={setReload}
									setTableLoadingState={setTableLoading}
									setTableErrorState={setTableError}
								/>
							</>
						}
					</>

				}
			</div>
		</>
	);
};

export default MobileDevicesOrgPage