
const columns: any[] = [
	{
		Header: "Hostname",
		accessor: "hostname",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span className=" dds__bold-12">{value}</span>
		}

	},
	{
		Header: "Service Tag",
		accessor: "serviceTag",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span className=" dds__bold-14">{value}</span>
		}

	},
	{
		Header: "Host ID",
		accessor: "hostID",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span className=" dds__bold-14">{value}</span>
		}

	},
	{
		Header: "Employee name",
		accessor: "employeeName",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span>{value}</span>
		}

	},
	{
		Header: "Email ID",
		accessor: "employeeEmail",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span>{value}</span>
		}

	},
	{
		Header: "Requested By",
		accessor: "requestBy",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span className=" dds__bold-14">{value}</span>
		}

	},
	{
		Header: "Reason",
		accessor: "reason",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span>{value}</span>
		}

	},
	{
		Header: "Notes",
		accessor: "notes",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: ({ value }) => {
			return <span>{value}</span>
		}

	},


];
export default columns;