

import React, { RefObject, ChangeEvent, useEffect } from 'react';

interface DDSSearchProps {
	searchRef: RefObject<HTMLDivElement>;
	searchText: string;
	setSearchText: (value: string) => void;
	disabled?: boolean;
	style?: any;
	placeholder?: string;
}

const DDSSearch: React.FC<DDSSearchProps> = ({ searchRef, searchText, setSearchText, disabled = false, style, placeholder = "Search" }) => {
	useEffect(() => {
		const buttons = searchRef.current?.getElementsByTagName('button');
		if (buttons) {
			for (let i = 0; i < buttons.length; i++) {
				buttons[i].disabled = disabled;
			}
		}
	}, [disabled, searchRef]);


	return (
		<div
			ref={searchRef}
			className="dds__search dds__mb-3"
			data-dds="search"
			role="search"
		>
			<div className="dds__search__wrapper">
				<input
					type="search"
					className="dds__search__control"
					name="search-control-888441747"
					// id="search-control-888441747"
					aria-labelledby="search-label-888441747"
					placeholder={placeholder}
					value={searchText}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						setSearchText(event.target.value);
					}}
					maxLength={35}
					disabled={disabled}
					style={style}

				/>
			</div>
		</div>
	)
}

export default DDSSearch