import styled from 'styled-components';

interface BoxWrapperProps {
	color?: string;
}

const BoxWrapper = styled.div.attrs({
	className: 'dds__elevation-1',
}) <BoxWrapperProps>`
  border-radius: 24px;
  padding: 24px;
  background: ${props => props.color || 'var(--gray-100)'};
`;

export default BoxWrapper;