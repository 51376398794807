import React from 'react'

const DeviceDetailsTable = ({ data, keyHeadingMap }) => {
	console.log("date", data)
	return (
		<table className="dds__w-100">
			<tbody className="dds__container">
				{Object.keys(data).map((key) => (
					<tr className="dds__row" key={key}>
						<th
							id={key}
							scope="row"
							className="dds__col--2 dds__col--md-4"
							style={{ color: "#0063B8", textAlign: "left" }}
						>
							{keyHeadingMap[key]}
						</th>
						<td
							headers={key}
							className="dds__col--2 dds__col--md-8"
						>
							<p
								className="dds__body-2"
								style={{
									wordBreak: "break-all",
									color: "#0E0E0E",
								}}
							>
								{data[key] == "null" ? "" : data[key]}
							</p>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default DeviceDetailsTable