/* eslint-disable react/destructuring-assignment */
import React from "react";
import Table from "../../Table/Table";


const ClientTable: React.FC<{
	data: any[]; // data to be displayed in the table, should have the same keys as the accessors defined in the data model
	columns: any[]; // data model to be displayed in the table
	activateFilters?: boolean; // whether to activate filters - data model should have disableFilters set to true with filter UI (refer to Data Model)

	activateExportAllData?: boolean; // whether to activate export all data
	activateExportCurrentView?: boolean; // whether to activate export current view
	skipStateReset: boolean; // Used to reset internal table state to default - pass state value in it
	instanceProps?: object; // extra props to be passed to the table to be used in cells 
	expandProps?: object; // extra props to be passed to the expand component
	disableToolbar?: boolean; // whether to disable toolbar
	activateSearch?: boolean; // whether to activate search
	defaultComplex?: boolean; // whether to set default rows view as crunched/complex rather open full row view
	canExpand?: boolean; // whether to enable expand functionality - this adds expand arrow and component to the table
	ExpandComponent?: React.FC<any>; // component to be displayed when expand is clicked
	disablePagination?: boolean; // displays all rows if disabled
	disableColumnFunctions?: boolean // disables column functions in toolbar like show/hide columns, crunch rows etc
	disableHeaderRow?:boolean // disables header Row

}> = props => <Table {...props} />;

export default ClientTable