import React, {
	ChangeEventHandler,
	useEffect,
	useState,
	useRef,
	useCallback,
	MouseEventHandler,
} from "react";
import { useAlert } from "react-alert";
import { useErrorHandler } from "react-error-boundary";
import { columns, mainColumns, expandColumns } from "./DataModelUser";
import {
	fetchUserRecordByReferenceConfig,
	fetchUserRecordsConfig,
} from "./API/api";
import { APIRequestFailedError, AuthError } from "../../ErrorStates";
import { Expand } from "./Expand/Expand";
import ClientTable from "../Common/ClientTable/ClientTable";
import useAxios from "../../hooks/useAxios";
import LoadingSpinner from "../Common/LoadingSpinner";
import { PageTitle } from "../AssetInventory/Admin/styles";
import FilterBarWrapper from "./FilterBarWrapper";
// Client table and useAxios with custom Error Handling 
const AssetTrackerUser: React.FC = () => {
	useEffect(() => {
		document.title = "Asset Request Tracker | My Requests"
	}, [])
	const FETCH_YOUR_RECORDS = "My Records";
	const FETCH_BY_REFERENCE = "Fetch By Reference";

	const handleError = useErrorHandler();
	const alert = useAlert();
	const [assetData, setAssetData] = useState<any[]>([]);

	const [skipStateReset, setSkipStateReset] = React.useState(false);

	React.useEffect(() => {
		setSkipStateReset(false);
	}, [assetData]);

	const {
		isLoading: isDataLoading,
		error: dataError,
		sendRequest: fetchAssetData,
	} = useAxios();

	const [isLoading, setIsLoading] = useState<boolean>(true); // Data
	const [loadingTable, setLoadingTable] = useState(false); // Table
	const [error, setError] = useState<string | null>(null);

	const [searchParam, setSearchParam] = useState<string>("");
	const [disableSearch, setDisableSearch] = useState(true);
	const [lastClicked, setLastClicked] = useState<string>("");

	const [reset, setReset] = useState<boolean>(false);

	// Set Asset Data
	const transformAssetData = useCallback((assetDataObj: any) => {
		const loadedAssetData: any = [];
		for (const asset in assetDataObj.records) {
			const data: any = {};
			Object.values([...mainColumns, ...expandColumns]).forEach(val => {
				const { accessor } = val;
				if (assetDataObj.records[asset][accessor] != null)
					data[accessor] = `${assetDataObj.records[asset][val.accessor]}`;
				else data[accessor] = "";
			});

			loadedAssetData.push(data);
		}

		setAssetData(loadedAssetData);
		// setAssetData(makeData(250000))
		setLoadingTable(false);
	}, []);

	useEffect(() => {
		fetchAssetData(fetchUserRecordsConfig(), transformAssetData);
		setLastClicked(FETCH_YOUR_RECORDS);
	}, [transformAssetData, reset]);

	// useEffect(() => {
	// 	console.log("changed", fetchAssetData);
	// }, [fetchAssetData]);

	const inputChangeHandler: ChangeEventHandler<HTMLInputElement> = e => {
		e.target.value.trim() === ""
			? setDisableSearch(true)
			: setDisableSearch(false);
		setSearchParam(e.target.value.trim());
	};

	const searchHandler: MouseEventHandler<HTMLButtonElement> = e => {
		setError(null);
		e.preventDefault();
		fetchAssetData(
			fetchUserRecordByReferenceConfig({
				reference_number: searchParam.toUpperCase(),
			}),
			transformAssetData
		);
		setLastClicked(FETCH_BY_REFERENCE);
	};

	useEffect(() => {
		setIsLoading(isDataLoading);
	}, [isDataLoading]);

	const columnsList = useRef<any>(columns);

	useEffect(() => {
		if (dataError) {
			if (dataError instanceof APIRequestFailedError) {
				if (dataError.statusCode !== "201") {
					handleError(dataError);
				} else {
					setError("No Record Found.");
				}
			} else if (dataError instanceof AuthError) {
				handleError(dataError);
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				);
			}
		}
	}, [alert, dataError, handleError]);

	const refreshHandler = (): void => {
		setSearchParam("");
		setDisableSearch(true)
		setError(null);
		setReset(prev => !prev);
	};

	return (
		<div className="dds__container-fluid dds__px-0">
			<div>
				<div className="dds__pb-2 dds__mb-2">
					<PageTitle display="inline">My Requests</PageTitle>
				</div>

				<FilterBarWrapper>
					<div className="dds__d-flex dds__flex-wrap">
						<div className="dds__d-flex dds__flex-grow-1">
							<input
								placeholder="Search by TASK/RITM"
								className="dds__input-text dds__my-2"
								type="text"
								value={searchParam}
								onChange={inputChangeHandler}
								style={{
									width: '100%',
									maxWidth: 600
								}}
							/>

							<button
								type="button"
								onClick={searchHandler}
								disabled={disableSearch}
								className="dds__button dds__button--primary dds__my-2 dds__ml-2"
							>
								Search
								{lastClicked === FETCH_BY_REFERENCE && (
									<i className="dds__icon dds__icon--refresh-spin dds__button__icon--end dds__icon" aria-hidden="true" style={{
										fontSize: '1rem'
									}}></i>
								)}
							</button>
						</div>

						<button
							type="button"
							onClick={refreshHandler}
							className="dds__button dds__button--secondary dds__my-2 dds__ml-4"
						>
							{FETCH_YOUR_RECORDS}
							{lastClicked === FETCH_YOUR_RECORDS && (
								<i className="dds__icon dds__icon--refresh-spin dds__button__icon--end dds__icon" aria-hidden="true" style={{
									fontSize: '1rem'
								}}></i>
							)}
						</button>
					</div>
				</FilterBarWrapper>
			</div>

			<div className="dds__mt-5">
				{isLoading && !error && (
					<div className="dds__mt-3 dds__mx-auto dds__text-center">
						<LoadingSpinner size="lg" label="Fetching" />
					</div>
				)}
				{!isLoading && loadingTable && !error && (
					<div className="dds__mt-3 dds__mx-auto dds__text-center">
						<LoadingSpinner size="lg" label="Loading" />
					</div>
				)}
				{!isLoading &&
					!loadingTable &&
					assetData.length > 0 &&
					!error &&
					!dataError && (
						<>
							<ClientTable
								data={assetData}
								columns={columnsList.current}
								skipStateReset={skipStateReset}
								activateFilters={false}
								activateExportAllData={false}
								activateExportCurrentView={false}
								activateSearch
								defaultComplex={false}
								canExpand
								ExpandComponent={Expand}
								expandProps={{
									allowEdit: false
								}}
							/>
						</>
					)}

				{!isLoading && assetData.length === 0 && !error && !loadingTable && (
					<p>No data Found. Please Try Again.</p>
				)}
				{error}
			</div>
		</div>
	);
};

export default AssetTrackerUser;