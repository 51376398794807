
import { ExportButton, SearchInput, CreateAdminButton } from "./ManageToolbar";

const ManageUserToolbar = () => {

	return <>
		<div className="dds__w-100">
			<div style={{backgroundColor:"var(--gray-100)",borderRadius:"10px",height:"80px"}}>
				<div
					className="dds__w-100 dds__h-100 dds__d-inline-block"
					style={{
						verticalAlign: "middle",
						position: "relative",
					}}
				>
					<div className=" dds__position-absolute"
						style={{
							top: "13%",
							left: "1%",
						}}>
						<SearchInput />
					</div>

					<div className=" dds__position-absolute"
						style={{
							top: "13%",
							right: "1%"
						}}>
						<span className="dds__mr-2">
							<CreateAdminButton />
						</span>
						<ExportButton />
					</div>
				</div>
			</div>
		</div>
	</>
}

export default ManageUserToolbar;