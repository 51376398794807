import { useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useAlert } from "react-alert";
import ReactTooltip from "react-tooltip";
// import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import DDSModal from "../../../Common/Modalv2/DDSModal";
import { IconButton } from "../../../Table/TableStyles";
import { WarningMessageBar } from "../MessageBarManage";
// import { deleteUserConfig } from "../../API";

const DeleteActionManageUser = ({ row, disabled }) => {
	// const alert = useAlert();
	// const queryClient = useQueryClient();
	// let modalInstanceRef = useRef<any>();

	// const deleteSuccessHandler = () => {

	// 	alert.show(`Successfully removed user ${row.values.admname} from the admin group`, {
	// 		type: 'success',
	// 		timeout: 5000,
	// 	})
	// 	queryClient.invalidateQueries([queryKeys.inventoryadminlist])
	// }

	// const deleteErrorHandler = () => {
	// 	alert.show("Unknown error occurred while deleting the record", {
	// 		type: 'error',
	// 		timeout: 10000,
	// 	})
	// }

	// const { isLoading: isDeleting, mutate: deleteRecord } = useCustomMutation(deleteUserConfig(row.values.ntid), {
	// 	onSuccess: deleteSuccessHandler,
	// 	onError: deleteErrorHandler
	// });

	// const deleteRecordHandler = () => {
	// 	deleteRecord({})
	// 	closeConfirmationModal();
	// }

	// const openConfirmationModal = () => {
	// 	modalInstanceRef.current.open();
	// };
	// const closeConfirmationModal = () => {
	// 	modalInstanceRef.current.close();
	// };

	const [show, setShow] = useState(false);
	const openConfirmationModal = () => {
		if (disabled) return;
		console.log("openConfirmationModal")
		setShow(true);
	};

	const closeConfirmationModal = () => {
		console.log("closeConfirmationModal")
		setShow(false);
	};

	return <><IconButton
		className="dds__button dds__button--secondary"
		data-for="deleteTicketTip"
		data-tip="Delete"
		style={{ marginTop: 0, marginBottom: 0 }}
		onClick={openConfirmationModal}
		// disabled={isDeleting}
		aria-label={"Delete admin"}
		disabled={disabled}
	>
		{/* {isDeleting && <LoadingSpinner size='md' />} */}
		{/* {!isDeleting && <i style={{ fontSize: '1.1rem', color: 'red' }} className="dds__icon dds__icon--trash" aria-hidden="true" />} */}
		<i style={{ fontSize: '1.1rem', color: 'red' }} className="dds__icon dds__icon--trash" aria-hidden="true" />
		<ReactTooltip id="deleteTicketTip" />

	</IconButton>
		{show && <WarningMessageBar EmployeeName={row.values.employeeName} EmployeeEmail={row.values.email} closeHandler={closeConfirmationModal} />}
	</>
}
export default DeleteActionManageUser;