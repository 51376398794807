import { useEffect } from "react";
// import { PageTitle } from "../../AssetInventory/Admin/styles";
import { quarantine_portal } from "../../Dashboardv3/constants";
// import DDSTabWrapper from "../../QuarantinePortal/DDSTabWrapper";
import ManageUsersPage from "./ManageUsersPage";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import { UnauthorizedError } from "../../../ErrorStates";
//DeviceTODO : Bring styles as a separate component

const ManageUsersLayout: React.FC = () => {
	// const tabsData = [
	// 	{
	// 		hidden: false,
	// 		active: false,
	// 		name: "Manage Administrator",
	// 		content: ManageUsersPage,
	// 		iconClass: "dds__icon--device-lock-screen",
	// 	},
	// ];
	useEffect(() => {
		document.title = "Quarantine Portal | Manage Users";
	}, []);

	const user = useUserInfo().getUserRole(quarantine_portal);
	useEffect(() => {
		if (!user.superAdmin)
			throw new UnauthorizedError()
	}, [])

	return (
		<div className="dds__container-fluid dds__px-0">
			{/* <PageTitle>Manage Users</PageTitle>
			<div style={{ height: "9px" }}></div> */}
			<ManageUsersPage />
			{/* <DDSTabWrapper
				tabsStyle={{
					contained: true,
					includeIcon: true,
					contentColor: "var(--blue-400)",
				}}
				tabsData={tabsData}
				salt={quarantine_portal}
				idName={quarantine_portal}
			/> */}
		</div>
	);
};

export default ManageUsersLayout;
