import React, { useContext } from 'react'
import ClientTable from '../../../Common/ClientTable/ClientTable'
import { ManageUsersContext } from './ManageUsersContext'
import ManageUsersToolbar from './Toolbar/Toolbar'

export const ManageUsersTable = ({ columns, CreateAdminButton }) => {
	const { filteredData } = useContext(ManageUsersContext)

	console.log("filtered data", filteredData)
	return (
		<>
			<ManageUsersToolbar CreateAdminButton={CreateAdminButton} />
			<div className="dds__mt-4">
				<ClientTable
					data={filteredData}
					columns={columns}
					skipStateReset={false}
					disableToolbar
				// instanceProps={setAllData}
				/>
			</div>
		</>
	)
}
