import { useState } from "react";
import { useHistory } from "react-router-dom";
import useMetaAPI from "../../DashboardPage/useMetaAPI";
import {
    SlidesWrapper,
    ButtonWrapper,
    Wrapper,
} from "../RightComponent/Styles";
import "../../../../App/DDS1 Specific Components/Carousel.scss";
import { CarouselWrapper } from "../../AnnouncementBanner/Styles";
import { IconButtonWrapper } from "../../Card/Styles";
import { useAnalytics } from "../../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../../store/analytics/eventsInfo";
import useResponsiveDragLayout from "../../DashboardPage/useResponsiveDragLayout";
const BannerRightComponent = () => {
    const { data: meta } = useMetaAPI();
    const history = useHistory();
    const { addEventToQueue } = useAnalytics();
    const { openCardDrawer } = useResponsiveDragLayout();

    // // Initializing Carousel
    // useEffect(() => {
    //     [].forEach.call(
    //         document.querySelectorAll('[data-ride="dds__carousel"]'),
    //         element => {
    //             new Carousel(element);
    //         }
    //     );
    // }); // Initializing using ref or ID doesn't work in DDS-uicore
    // Need empty dependency array - issue related to DDS-uicore

    const prev = () => {
        setActiveIndex(
            (activeIndex - 1 + meta.orderUserActions.length) %
            meta.orderUserActions.length
        );
    };

    const next = () => {
        setActiveIndex((activeIndex + 1) % meta.orderUserActions.length);
    };

    const handleRedirection = (
        path: string,
        external: boolean,
        cardName: string
    ) => {
        // Add Event
        addEventToQueue({
            ...eventsInfo["redirect dashboard banner"](),
            additional_payload: {
                path,
                external,
                cardName,
            },
        });
        if (external) window.open(path);
        else history.push(path);
    };

    const [activeIndex, setActiveIndex] = useState(0);
    console.log("test", meta, meta.orderUserActions)
    if (meta === null || meta.orderUserActions.length === 0) return <></>;

    return (
        <Wrapper className="dds__d-flex dds__flex-column dds__align-items-center dds__align-self-center dds__my-3 dds__m-lg-0">
            <SlidesWrapper className="dds__px-3 dds__py-2 dds__mb-1">
                <CarouselWrapper
                    id="actionCarousel"
                    className="dds__carousel"
                    data-ride="dds__carousel"
                    data-interval="0"
                    data-pause="true"
                >
                    <div
                        className="dds__carousel-inner dds__py-1"
                        style={{
                            // minWidth: 400,
                            width: "max-content",
                        }}
                    >
                        {meta.orderUserActions.map((cardName: string, index: number) => (
                            <div
                                key={index}
                                className={`dds__carousel-item ${index === activeIndex && " dds__active"
                                    }`}
                            >
                                <div className="dds__d-flex dds__flex-column dds__flex-sm-row dds__flex-md-column dds__flex-lg-row dds__justify-content-between dds__align-items-center">
                                    <span className=" dds__body-2 dds__mr-3" style={{
                                        fontWeight: "500"
                                    }}>
                                        {meta.data[cardName]}
                                    </span>
                                    {meta.url[cardName] && meta.url[cardName].openDrawer &&
                                        <button
                                            className="dds__button dds__button--sm"
                                            onClick={() => openCardDrawer(cardName)}
                                        >
                                            View
                                        </button>
                                    }
                                    {meta.url[cardName] && !meta.url[cardName].openDrawer && (
                                        <button
                                            aria-label={meta.url[cardName].ariaLabel}
                                            onClick={() =>
                                                handleRedirection(
                                                    meta.url[cardName].path,
                                                    meta.url[cardName].external,
                                                    cardName
                                                )
                                            }
                                            className="dds__button dds__button--sm"
                                        >
                                            {meta.url[cardName].name}
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </CarouselWrapper>
            </SlidesWrapper>
            {/* Add buttons to increase decrease activeIndex within the range of meta.orderUserActions.length */}
            {meta.orderUserActions.length > 1 && (
                <ButtonWrapper className="dds__d-flex dds__px-2 dds__py-1">
                    <IconButtonWrapper
                        aria-label={"next action"}
                        role={"button"}
                        type={"button"}
                        onClick={prev}
                        className="dds__button dds__button--secondary dds__button--md  dds__border-0 dds__p-0"
                    >
                        <i
                            style={{
                                color: "var(--blue-600)",
                            }}
                            className={` dds__icon dds__icon--chevron-left`}
                            aria-hidden="true"
                        />
                    </IconButtonWrapper>
                    {/* Which slide out of total that is active with text color #00227F;*/}
                    <div className="dds__d-flex dds__align-items-center dds__mx-2">
                        <span className="dds__font-weight-normal dds__font-size-normal dds__mr-2">
                            {activeIndex + 1}
                        </span>
                        <span className="dds__font-weight-light dds__font-size-normal">
                            / {meta.orderUserActions.length}
                        </span>
                    </div>
                    <IconButtonWrapper
                        aria-label={"next action"}
                        role={"button"}
                        type={"button"}
                        onClick={next}
                        className="dds__button dds__button--secondary dds__button--md  dds__border-0 dds__p-0"
                    >
                        <i
                            style={{
                                color: "var(--blue-600)",
                            }}
                            className={` dds__icon dds__icon--chevron-right`}
                            aria-hidden="true"
                        />
                    </IconButtonWrapper>
                </ButtonWrapper>
            )}
        </Wrapper>
    );
};

export default BannerRightComponent;
