import DefaultCell from "../../Table/ReusableTableCells/DefaultCell";

const columns: any[] = [
	{
		Header: "Hostname",
		accessor: "hostname",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Employee Email",
		accessor: "employee_email",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Action Taken",
		accessor: "actionTaken",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Action Status",
		accessor: "actionStatus",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Reason",
		accessor: "reason",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Notes",
		accessor: "notes",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Submitted By",
		accessor: "submittedBy",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Time Logged",
		accessor: "timeLogged",
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Source",
		accessor: "source",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Service Tag",
		accessor: "serviceTag",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		show: false,
		Cell: DefaultCell,
	},

	{
		Header: "Machine ID",
		accessor: "hostID",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Bulk Action",
		accessor: "bulkAction",
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		show: false,
		Cell: DefaultCell,
	},
	{
		Header: "Managed",
		accessor: "managed",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		show: false,
		Cell: DefaultCell,
	},
	{
		Header: "Employee Name",
		accessor: "employee_name",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		show: false,
		Cell: DefaultCell,
	},
	{
		Header: "Error Message",
		accessor: "errorMsg",
		disableFilters: true,
		disableSortBy: true,
		width: 60,
		show: false,
		Cell: DefaultCell,
	},
];
export default columns;
