import { useState, useEffect } from 'react';

export const useLoadingMessage = (isFetching) => {
	const [loadingMessage, setLoadingMessage] = useState("Searching...");

	useEffect(() => {
		let timer;
		if (isFetching)
			timer = setTimeout(() => {
				setLoadingMessage(
					"It's taking longer than expected, please bear with us"
				);
			}, 2000); // 2 seconds
		return () => {
			setLoadingMessage("Searching...");
			clearTimeout(timer);
		}; // cleanup on component unmount
	}, [isFetching]);

	return loadingMessage;
};