import React from "react";
import comingSoon from "../../../../Assets/Coming_Soon_Chart_2.png"
import useContainerWidth from "../../useContainerWidth";
import { ComingSoonWrapper } from "./Styles";
// import { useCustomQuery } from "../../../../hooks/useCustomQuery";
// import { queryKeys } from "../../../../react-query/constants";
// import { fetchUserRecordsConfig } from "../../../AssetTracker/API/api";
// import LoadingSpinner from "../../../Common/LoadingSpinner";
// import CardError from "../CardError";
// import DDSAccordian from "../../Accordian/DDSAccordian";
// import { req_tracker } from "../../constants";
// import NoRecordFound from "../../CardTable/NoRecordFound";

export const createAccordianID = (name: string) => {
	return `accordian-${name.replace(/\s/g, "")}`
}
const Admin: React.FC = () => {

	// if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	// if (isError) return <CardError />;

	// if (data.records.length === 0)
	// 	return <NoRecordFound />
	// Coming soon image
	return (
		<ComingSoonWrapper>
			<img src={comingSoon} alt="Coming Soon" />
		</ComingSoonWrapper>

	);
};

export default Admin;
