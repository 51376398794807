
type propType = {
    SwitchRef?:any
    heading : string;
    onLabel: string;
    offLabel: string;
    small?: boolean;
    alignStart?: boolean;
    onClick?: any;
    ariaChecked?: boolean;
}
const SwitchComponent = (props:propType) => { 
    let className = "dds__switch";
    if (props.small){
        className += " dds__switch--sm";
    }
    if (props.alignStart){
        className += " dds__switch--horizontal-start";
    }
    return (
        <div ref = {props.SwitchRef} role="switch" data-dds="switch" tabIndex={0} className={className} id={`${props.heading} ${Math.random()}`}  onClick={props.onClick} aria-checked={props.ariaChecked?props.ariaChecked:"false"}>
            <span className="dds__switch__label">{props.heading}</span>
            <div className="dds__switch__area" aria-hidden="true">
                <span className="dds__switch__track"><span className="dds__switch__handle"></span></span>
                <span className="dds__switch__control-label">
                <span className="dds__switch__control-label__on">{props.onLabel}</span>
                <span className="dds__switch__control-label__off">{props.offLabel}</span>
                </span>
            </div>
        </div>
    );
};

export default SwitchComponent;