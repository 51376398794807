import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import { fetchPasswordManagementDataDashboardConfig } from "../API";

export const usePasswordManagementData = () => {
    return useCustomQuery([queryKeys.PasswordManagementCard], fetchPasswordManagementDataDashboardConfig(), {
        select: data => {
            return {
                ...data,
                message: data.result,
            }    
        },
    });
};
