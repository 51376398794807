import { useRef, useState, useContext, useEffect } from "react";
import AdminModal from "../Modal/AdminModal";
import { ReactComponent as SearchIcon } from "../../../../Assets/Search.svg";
import { ManageUserBlockedContext } from "../ManageUserBlockedContext";
import { ReactComponent as ExportIcon } from "./ExportCsv.svg";
import { SuccessMessageBar } from "../MessageBarManage";
import { useUserInfo } from "../../../../store/auth/UserInfoContext";


const ToolbarButton: React.FC<{
	onClick: () => void,
	iconClass?: string,
	classN?: string
}> = ({ onClick, iconClass, classN = "", children }) => {
	const enabled = useUserInfo().getUserInfo().userRole.blockDevices.create;
	return <button
		onClick={onClick}
		className={"dds__button dds__button--md " + classN}
		type="button"
		style={{
			lineHeight: "2.0rem",
			verticalAlign: "middle",
		}}
		disabled={!enabled}
	>
		{iconClass && <i className={`dds__icon dds__button__icon--start ${iconClass}`} />}
		{children}
	</button>
}

export const CreateAdminButton = () => {
	const [show, setShow] = useState(false);
	const modalInstanceRef = useRef<any>();
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (show && modalInstanceRef.current) {
			modalInstanceRef.current.open();
		}
	}, [show]);

	return <>
		<ToolbarButton onClick={() => {
			setShow(true);
		}} iconClass="dds__icon--user-add"
		>Create Admin</ToolbarButton>
		{show && (
			<AdminModal
				handleClose={() => {
					setShow(false);
				}}
				mode="new"
				modalInstanceRef={modalInstanceRef}
				setShowSuccess={setShowSuccess}
			></AdminModal>
		)}
		{showSuccess && <SuccessMessageBar mode={"new"} />}
	</>
};


export const SearchInput = () => {
	const { filterData } = useContext(ManageUserBlockedContext)
	const filterHandler = (e) => {
		filterData(e.target.value)
	}
	return <div
		className="dds__input-text__wrapper dds__input-text__container--lg dds__input-text__wrapper--button"
		style={{
			width: 400
		}}
	>
		<input
			// style={{
			// 	lineHeight: '2.2rem'
			// }}
			// disabled={props.isFetching}
			// onChange={props.searchInputChangeHandler}
			// onKeyPress={e => e.key === "Enter" && props.searchHandler()}
			type="text"
			className="dds__input-text"
			placeholder="Search"
			onChange={filterHandler}
		/>

		<button
			style={{ minWidth: 50, backgroundColor: "white" }}
			className="dds__button dds__button--secondary dds__button--md"
			type="button"
		// onClick={props.searchHandler}
		// disabled={props.isFetching}
		>
			<SearchIcon
				style={{
					margin: 0,
					// marginLeft: "2.4rem",
					// marginTop: `${props.searchOnly ? "0.5rem" : "0.2rem"}`,
					// width: "1.5rem",
				}}
				className="dds__icon dds__button__icon--end"
			/>
		</button>
	</div>
};

export const ExportButton = () => {
	const { filteredData } = useContext(ManageUserBlockedContext);
	const HandleExport = () => {
		const data = filteredData.map(item => {
			const { permission, ...rest } = item;
			return {
				...rest,
				...permission
			};
		});
		const column = Object.keys(data[0]);
		const csvContent = "data:text/csv;charset=utf-8," + [column.join(","), ...data.map(row => Object.values(row).join(","))].join("\n");
		const anchor = document.createElement("a");
		anchor.href = encodeURI(csvContent);
		anchor.target = "_blank";
		anchor.download = "data.csv";
		anchor.click();
	};

	return (
		<>
			<ToolbarButton onClick={HandleExport} classN="dds__button--secondary">
				<ExportIcon style={{ marginRight: 10 }} />
				Export as CSV
			</ToolbarButton>
		</>
	);
}

