import { PageTitle } from "../Admin/styles";
import { StatCard } from './StatCard/StatCard';
import ServerTable from '../../Common/ServerTable/ServerTable';
import { useHistory, useParams } from "react-router-dom";
import { getLeaderBoardConfig, leaderAssetDetailConfig, leaderStockDetailConfig } from '../API';
import { ReactComponent as Icon } from '../../../Assets/dds2_user-group.svg'
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useErrorHandler } from "react-error-boundary";
import { UnauthorizedError } from "../../../ErrorStates";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import { useStatCardData } from './StatCard/StatCardAPI';

import ProductView from './Buttons/ProductView';
import StockView from './Buttons/StockView';
import { leaderProductDetailConfig } from '../API';
import AssetView from './Buttons/AssetView';
import { inventory } from "../../Dashboardv3/constants";

const LeaderBoardPage = () => {
    useEffect(() => {
        document.title = "Asset Inventory | Leaderboard"
    }, [])

    const handleError = useErrorHandler();
    const user = useUserInfo();
    const [legalBadge, setLegalBadge] = useState<string>();
    const [admin, setAdmin] = useState(false)

    //Store the triggers for each Views
    const [triggerProductView, setTriggerProductView] = useState(false);
    const [triggerStockView, setTriggerStockView] = useState(false);
    const [triggerAssetView, setTriggerAssetView] = useState(false);

    //Store the header for each Views
    const [headerProductView, setheaderProductView] = useState("");
    //Store the URL Params for each Views
    const [urlLeaderProductView, seturlLeaderProductView] = useState({
        leaderBadgeID: "",
        level: ""
    });

    useEffect(() => {
        if (user) {
            const res = user?.getUserInfo();
            const role = user?.getUserRole(inventory);
            setLegalBadge(res.badgeId);
            if (role.admin || role.superAdmin || role.leader) {
                setAdmin(true)
            } else {
                handleError(new UnauthorizedError())
            }
        }
    }, [user, handleError]);

    const urlParams = useParams<{
        l3_manager?: string
        l4_manager?: string
        l5_manager?: string
        l6_manager?: string
        l7_manager?: string
    }>();

    let current_level = 'l3';

    if (urlParams.l3_manager)
        current_level = 'l4'
    if (urlParams.l4_manager)
        current_level = 'l5'
    if (urlParams.l5_manager)
        current_level = 'l6'
    if (urlParams.l6_manager)
        current_level = 'l7'
    if (urlParams.l7_manager)
        current_level = 'l8'

    const history = useHistory();

    const encodeHashUriComponent = (text: string) => {
        const b64encoding = window.btoa(text);
        return encodeURIComponent(b64encoding);
    }

    const decodeHashUriComponent = (text: string) => {
        const decodedUriComponent = decodeURIComponent(text);
        const b64decoding = window.atob(decodedUriComponent);
        return b64decoding;
    }

    const redirect = (current_level: string, leaderName: string, leaderBadge: string, heirarchy: any, team: boolean, preencoded?: boolean) => {
        const encoded_leader = encodeHashUriComponent(leaderBadge + "###" + leaderName);
        const encoded_l3 = preencoded ? heirarchy.l3 : encodeHashUriComponent(heirarchy.l3);
        const encoded_l4 = preencoded ? heirarchy.l4 : encodeHashUriComponent(heirarchy.l4);
        const encoded_l5 = preencoded ? heirarchy.l5 : encodeHashUriComponent(heirarchy.l5);
        const encoded_l6 = preencoded ? heirarchy.l6 : encodeHashUriComponent(heirarchy.l6);
        if (current_level.toLowerCase() === 'l3')
            history.push(`/assetinventory/leaderboard/${encoded_leader}?team=${team}`)
        else if (current_level.toLowerCase() === 'l4')
            history.push(`/assetinventory/leaderboard/${encoded_l3}/${encoded_leader}?team=${team}`)
        else if (current_level.toLowerCase() === 'l5')
            history.push(`/assetinventory/leaderboard/${encoded_l3}/${encoded_l4}/${encoded_leader}?team=${team}`)
        else if (current_level.toLowerCase() === 'l6')
            history.push(`/assetinventory/leaderboard/${encoded_l3}/${encoded_l4}/${encoded_l5}/${encoded_leader}?team=${team}`)
        else if (current_level.toLowerCase() === 'l7')
            history.push(`/assetinventory/leaderboard/${encoded_l3}/${encoded_l4}/${encoded_l5}/${encoded_l6}/${encoded_leader}?team=${team}`)
    }

    const disableViewCheck = (badgeId: string) => {
        if (admin) {
            return false;
        }
        else {
            if (legalBadge === badgeId) {
                return false;
            }
            else { //If a leader Badge Id is present in URL params , it can access it's subleader data
                if (urlParams.l3_manager && (decodeHashUriComponent(urlParams.l3_manager).split("###")[0] === legalBadge))
                    return false;
                else if (urlParams.l4_manager && (decodeHashUriComponent(urlParams.l4_manager).split("###")[0] === legalBadge))
                    return false;
                else if (urlParams.l5_manager && (decodeHashUriComponent(urlParams.l5_manager).split("###")[0] === legalBadge))
                    return false;
                else if (urlParams.l6_manager && (decodeHashUriComponent(urlParams.l6_manager).split("###")[0] === legalBadge))
                    return false;
                else if (urlParams.l7_manager && (decodeHashUriComponent(urlParams.l7_manager).split("###")[0] === legalBadge))
                    return false;
                else
                    return true;
            }
        }

    }

    //Storing all Columns related to LeaderBoard and all Views
    const leaderActionColumn = {
        Header: "Action",
        accessor: "nextlevel",
        disableFilters: true,
        disableSortBy: true,
        width: 130,
        show: true, // current_level === 'l6' ? false : true
        disableExport: true,
        Cell: ({ value, row, column }) => {
            return (
                <span>
                    {/* {((legalName && (legalName.toLowerCase() === row.values['leaderName'].toLowerCase())) || admin) &&
                        <ExportButton leader={row.values['leaderName']} level={row.values['level']} />} */}
                    {value && (
                        <>
                            <button
                                hidden={row.values['emptype'] === "direct"}
                                onClick={() => {
                                    redirect(
                                        row.values['level'],
                                        row.values['leaderName'],
                                        row.values['leaderBadge'],
                                        {
                                            l3: urlParams?.l3_manager,
                                            l4: urlParams?.l4_manager,
                                            l5: urlParams?.l5_manager,
                                            l6: urlParams?.l6_manager,
                                            l7: urlParams?.l6_manager
                                        },
                                        row.values['emptype'] === "manager",
                                        true,
                                    )
                                }
                                }
                                className="dds__button dds__button--sm dds__button--secondary dds__ml-2 dds__mb-1"
                                aria-label={"View " + row.values['emptype'] === "manager" ? "Team" : value.toUpperCase()}

                            >
                                View {row.values['emptype'] === "manager" ? "Team" : value.toUpperCase()}
                                <i
                                    style={{ fontSize: "1.2rem" }}
                                    className="dds__icon dds__icon--arrow-right dds__button__icon--end"
                                ></i>
                            </button>
                        </>
                    )}

                </span>
            )
        },
    }

    const firstIconColumn = {
        Header: "#",
        accessor: "ico",
        disableFilters: true,
        disableSortBy: true,
        disableExport: true,
        width: 5,
        Cell: ({ }) => {
            return (
                <div style={{
                    borderRadius: '50%',
                    background: '#0076CE',
                    width: 32,
                    height: 32,
                    textAlignLast: 'center',
                    position: 'relative'
                }}>
                    <i
                        style={{ fontSize: "0.8rem", color: 'white', position: 'absolute', top: '2%', right: '47%' }}
                        className="dds__icon dds__icon--user dds__py-1 dds__px-2 dds__d-none dds__d-sm-block dds__mx-auto dds__pt-2"

                    />
                </div>
            )
        },
    };

    const commonColumns: any[] = [

        {
            Header: "Leader Name",
            accessor: "leaderName",
            disableFilters: true,
            disableSortBy: false,
            // width: 80,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="leader_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value}</strong>

                    </p>
                    <ReactTooltip id="leader_tip" />
                </>
            ),
        },
        {
            Header: "Total Assets",
            accessor: "totalassets",
            disableFilters: true,
            disableSortBy: false,
            width: 112,
            Cell: ({ row, value }) => (
                <div className="dds__w-100 dds__d-flex dds__justify-content-between">
                    <p
                        style={{ display: "inline", color: "var(--blue-800)", fontWeight: 500 }}
                        data-for="total_asset_tip"
                        data-tip={value}
                    >
                        {new Number(value).toLocaleString()}
                    </p>

                    <button
                        hidden={row.values["totalassets"] === "0"}
                        data-for="total_asset_button_tip"
                        title={disableViewCheck(row.values["leaderBadge"]) ? "You don't have permission to access this." : ""}
                        disabled={disableViewCheck(row.values["leaderBadge"])}
                        aria-label={"View Total Assets"}
                        style={{ minWidth: '45px' }}
                        className="dds__button dds__button--sm dds__button--secondary dds__ml-2"
                        onClick={() => {
                            setTriggerAssetView(true);
                            setheaderProductView(row.values['leaderName']);
                            seturlLeaderProductView({ leaderBadgeID: row.values['leaderBadge'], level: current_level.toUpperCase() })
                        }}
                    >
                        <i style={{ fontSize: "1.2rem" }} className="dds__icon dds__icon--eye-view-on dds__button__icon--start" />
                        View
                    </button>
                    <ReactTooltip id="total_asset_button_tip" />
                    <ReactTooltip id="total_asset_tip" />
                </div>
            ),
        },

        {
            Header: "Funded By Business",
            accessor: "bufundingcount",
            disableFilters: true,
            disableSortBy: false,
            width: 72,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline", color: "var(--blue-800)", fontWeight: 500 }}
                        data-for="bufunding_tip"
                        data-tip={value}
                    >
                        {value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["bufundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}
                    </p>
                    <ReactTooltip id="bufunding_tip" />

                </>
            ),
        },
        {
            Header: "Funded By IT",
            accessor: "itfundingcount",
            disableFilters: true,
            disableSortBy: false,
            width: 72,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline", color: "var(--blue-800)", fontWeight: 500 }}
                        data-for="itfunding_tip"
                        data-tip={value}
                    >
                        {value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["itfundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}
                    </p>
                    <ReactTooltip id="itfunding_tip" />

                </>
            ),
        },
        {
            Header: "Not Funded",
            accessor: "notfundingcount",
            disableFilters: true,
            disableSortBy: false,
            width: 72,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline", color: "var(--blue-800)", fontWeight: 500 }}
                        data-for="notfunding_tip"
                        data-tip={value}
                    >
                        {value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["notfundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}
                    </p>
                    <ReactTooltip id="notfunding_tip" />

                </>
            ),
        },
        {
            Header: "Level",
            accessor: "level",
            show: false,
            disableFilters: true,
            disableSortBy: false,
            disableExport: true,
            // width: 80,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                    >
                        <b>{value.toUpperCase()}</b>
                    </p>
                </>
            ),
        },
        {
            Header: "Hierarchy",
            accessor: "hierarchy",
            show: false,
            disableFilters: true,
            disableSortBy: false,
            disableExport: true,
            // width: 80,
            Cell: ({ value }) => null,
        },
        {
            Header: "BadgeId",
            accessor: "leaderBadge",
            show: false,
            disableFilters: true,
            disableSortBy: false,
            disableExport: true,
            // width: 80,
            Cell: ({ value }) => null,
        },
        {
            Header: "Products",
            accessor: "productname",
            disableFilters: true,
            disableSortBy: true,
            disableExport: true,
            width: 72,
            Cell: ({ value, row }) => {

                return (<div className="dds__w-100 dds__d-flex dds__justify-content-between">

                    {<div>
                        <button
                            hidden={row.values["totalassets"] === "0"}
                            disabled={disableViewCheck(row.values["leaderBadge"])}
                            data-for="product_button_tip"
                            title={disableViewCheck(row.values["leaderBadge"]) ? "You don't have permission to access this." : ""}
                            aria-label='View Products Data' style={{ minWidth: '90px' }}
                            className="dds__button dds__button--sm dds__button--secondary dds__ml-2"
                            onClick={() => {
                                setTriggerProductView(true);
                                setheaderProductView(row.values['leaderName']);
                                seturlLeaderProductView({ leaderBadgeID: row.values['leaderBadge'], level: current_level.toUpperCase() })
                            }}
                        >
                            <i style={{ fontSize: "1.2rem" }} className="dds__icon dds__icon--eye-view-on dds__button__icon--start" />
                            View
                        </button>
                        <ReactTooltip id="product_button_tip" />
                    </div>}
                </div>
                )
            },
        },
        {
            Header: "Stockroom",
            accessor: "stockroom",
            disableFilters: true,
            disableSortBy: true,
            disableExport: true,
            width: 72,
            Cell: ({ value, row }) => {
                return (<div className="dds__w-100 dds__d-flex dds__justify-content-between">

                    {<div>
                        <button
                            hidden={row.values["totalassets"] === "0"}
                            disabled={disableViewCheck(row.values["leaderBadge"])}
                            data-for="stock_button_tip"
                            title={disableViewCheck(row.values["leaderBadge"]) ? "You don't have permission to access this." : ""}
                            aria-label="View StockRoom Data" style={{ minWidth: '90px' }}
                            className="dds__button dds__button--sm dds__button--secondary dds__ml-2 "
                            onClick={() => {
                                setTriggerStockView(true);
                                setheaderProductView(row.values['leaderName']);
                                seturlLeaderProductView({ leaderBadgeID: row.values['leaderBadge'], level: current_level.toUpperCase() })
                            }}
                        >
                            <i style={{ fontSize: "1.2rem" }} className="dds__icon dds__icon--eye-view-on dds__button__icon--start" />
                            View
                        </button>
                        <ReactTooltip id="stock_button_tip" />
                    </div>}
                </div>
                )
            },
        },
        {
            Header: "Employee Type",
            accessor: "emptype",
            show: false,
            disableFilters: true,
            disableSortBy: false,
            disableExport: true,
            // width: 80,
            Cell: ({ value }) => null
        }

    ];

    // const firstSerialColumn = {
    //     Header: "#",
    //     // accessor: "serial",
    //     disableFilters: true,
    //     disableSortBy: true,
    //     disableExport: true,
    //     width: 5,
    //     Cell: ({ row, value }) => {
    //         return (
    //             <div>
    //                 <strong
    //                     style={{ fontSize: "0.9rem", position: 'absolute', top: '2%', right: '47%' }}
    //                     className="dds_body dds__mt-2"

    //                 >{row.index + 1}</strong>
    //             </div>
    //         )
    //     },
    // };
    const leaderProductView: any[] = [
        {
            Header: "Products Name",
            accessor: "model_name",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="product_name_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value}</strong>

                    </p>
                    <ReactTooltip id="product_name_tip" />
                </>
            ),
        },
        {
            Header: "Asset Count",
            accessor: "totalassets",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="asset_count_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value}</strong>

                    </p>
                    <ReactTooltip id="asset_count_tip" />
                </>
            ),
        },
        {
            Header: "Funded By Business",
            accessor: "bufundingcount",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="business_funded_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["bufundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}</strong>

                    </p>
                    <ReactTooltip id="business_funded_tip" />
                </>
            ),
        },
        {
            Header: "Funded By IT",
            accessor: "itfundingcount",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="it_funded_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["itfundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}</strong>

                    </p>
                    <ReactTooltip id="it_funded_tip" />
                </>
            ),
        },
        {
            Header: "Not Funded",
            accessor: "notfundingcount",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="not_funded_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["notfundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}</strong>

                    </p>
                    <ReactTooltip id="not_funded_tip" />
                </>
            ),
        }
    ]

    const leaderStockView: any[] = [
        {
            Header: "Country",
            accessor: "country",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="country_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value}</strong>

                    </p>
                    <ReactTooltip id="country_tip" />
                </>
            ),
        },
        {
            Header: "Stockroom",
            accessor: "name",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="stockroom_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value}</strong>

                    </p>
                    <ReactTooltip id="stockroom_tip" />
                </>
            ),
        },
        {
            Header: "Asset Count",
            accessor: "totalassets",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="totalasset_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value}</strong>

                    </p>
                    <ReactTooltip id="totalasset_tip" />
                </>
            ),
        },
        {
            Header: "Funded By Business",
            accessor: "bufundingcount",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="business_funded_stock_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["bufundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}</strong>

                    </p>
                    <ReactTooltip id="business_funded_stock_tip" />
                </>
            ),
        },
        {
            Header: "Funded By IT",
            accessor: "itfundingcount",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="it_funded_stock_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["itfundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}</strong>

                    </p>
                    <ReactTooltip id="it_funded_stock_tip" />
                </>
            ),
        },
        {
            Header: "Not Funded",
            accessor: "notfundingcount",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ row, value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="not_funded_stock_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value + ((row.values["totalassets"] !== "0") ? " (" + (row.values["notfundingcount"] * 100 / row.values["totalassets"]).toFixed(1) + "%)" : "")}</strong>

                    </p>
                    <ReactTooltip id="not_funded_stock_tip" />
                </>
            ),
        }
    ]

    const leaderAssetView: any[] = [
        {
            Header: "Employee ID",
            accessor: "assigned_to_u_dell_employee_id",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="employee_id_tip"
                        data-tip={value}
                    >
                        <strong className='dds__body-2'>{value}</strong>

                    </p>
                    <ReactTooltip id="employee_id_tip" />
                </>
            ),
        },
        {
            Header: "Employee Email",
            accessor: "assigned_to_email",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="employee_email_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="employee_email_tip" />
                </>
            ),
        },
        {
            Header: "Service Tag",
            accessor: "serial_number",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="serialnumber_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="serialnumber_tip" />
                </>
            ),
        },
        {
            Header: "Product",
            accessor: "model_name",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="model_name_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="model_name_tip" />
                </>
            ),
        },
        {
            Header: "SHIR Enabled",
            accessor: "is_shir",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="shir_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="shir_tip" />
                </>
            ),
        },
        {
            Header: "Asset Location",
            accessor: "location",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="location_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="location_tip" />
                </>
            ),
        },
        {
            Header: "Asset Condition",
            accessor: "device_condition",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="device_condition_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="device_condition_tip" />
                </>
            ),
        },
        {
            Header: "Asset Funded By",
            accessor: "funding",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="asset_funded_by_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="asset_funded_by_tip" />
                </>
            ),
        },
        {
            Header: "Next Funding",
            accessor: "funding_on_eol",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="next_funding_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="next_funding_tip" />
                </>
            ),
        },
        {
            Header: "Cost Center",
            accessor: "cost_center_name",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="cost_center_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="cost_center_tip" />
                </>
            ),
        },
        {
            Header: "Status",
            accessor: "statusvalue",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="status_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="status_tip" />
                </>
            ),
        },
        {
            Header: "Sub Status",
            accessor: "substatus",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="sub_status_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value}</div>

                    </p>
                    <ReactTooltip id="sub_status_tip" />
                </>
            ),
        },
        {
            Header: "End of Life",
            accessor: "endoflife",
            disableFilters: true,
            disableSortBy: false,
            Cell: ({ value }) => (
                <>
                    <p
                        style={{ display: "inline" }}
                        data-for="end_of_life_tip"
                        data-tip={value}
                    >
                        <div className='dds__body-2'>{value + " days"}</div>

                    </p>
                    <ReactTooltip id="end_of_life_tip" />
                </>
            ),
        }

    ]

    // leaderProductView.unshift(firstSerialColumn);
    // leaderStockView.unshift(firstSerialColumn);
    // leaderAssetView.unshift(firstSerialColumn);

    const leaderTableColumns = commonColumns.concat(leaderActionColumn);
    leaderTableColumns.unshift(firstIconColumn);

    let urlSearch = new URL(window.location.href)
    let headerLeaderBoard = (urlSearch.searchParams.get("team") === "true") ? `Team Members - ${decodeHashUriComponent(urlParams[`l${parseInt(current_level[1]) - 1}_manager`]).split("###")[1]}` : `${current_level.toUpperCase()} Leader Board ${current_level !== 'l3' ? `- ${decodeHashUriComponent(urlParams[`l${parseInt(current_level[1]) - 1}_manager`]).split("###")[1]}` : ""}`;

    return (
        <div className="dds__container-fluid dds__px-0 ">
            <div>
                <div className="dds__pb-2 dds__mb-2">
                    <PageTitle display={"inline"}>Leader Wise Asset Inventory</PageTitle>
                </div>

                <StatCard apiFunction={useStatCardData} enablePercentage={true} />

                <ProductView trigger={triggerProductView} setTriggerProductView={setTriggerProductView} leaderName={headerProductView}>
                    <div className='dds__mx-4'>
                        <ServerTable
                            apiArgs={[urlLeaderProductView]}
                            apiConfig={leaderProductDetailConfig}
                            heading={(urlSearch.searchParams.get("team") === "true") ? `Team Member - ${headerProductView}` : `${current_level.toUpperCase()} Leader Board - ${headerProductView}`}
                            Icon={Icon}
                            columns={leaderProductView}
                            searchHeader="Search by Product Name"
                            tableBorder={false}
                            disableColumnFunctions
                        />
                    </div>
                </ProductView>

                <StockView trigger={triggerStockView} setTriggerStockView={setTriggerStockView} leaderName={headerProductView}>
                    <div className='dds__mx-4'>
                        <ServerTable
                            apiArgs={[urlLeaderProductView]}
                            apiConfig={leaderStockDetailConfig}
                            heading={(urlSearch.searchParams.get("team") === "true") ? `Team Member - ${headerProductView}` : `${current_level.toUpperCase()} Leader Board - ${headerProductView}`}
                            Icon={Icon}
                            columns={leaderStockView}
                            searchHeader="Search by Stock Name"
                            tableBorder={false}
                            disableColumnFunctions
                        />
                    </div>
                </StockView>

                <AssetView trigger={triggerAssetView} setTriggerAssetView={setTriggerAssetView} leaderName={headerProductView}>
                    <div className='dds__mx-4'>
                        <ServerTable
                            apiArgs={[urlLeaderProductView]}
                            apiConfig={leaderAssetDetailConfig}
                            heading={(urlSearch.searchParams.get("team") === "true") ? `Team Member - ${headerProductView}` : `${current_level.toUpperCase()} Leader Board - ${headerProductView}`}
                            Icon={Icon}
                            columns={leaderAssetView}
                            searchHeader="Search by Asset No./Emp ID/Product Name"
                            tableBorder={false}
                            disableColumnFunctions
                        />
                    </div>
                </AssetView>

                <div className='dds__mb-3' id="back" style={{ textAlign: 'right' }} >
                    {(current_level === 'l3') ? <div style={{ height: '24px' }}></div> :
                        <button aria-label='Go Back' className='dds__button dds__button--secondary dds__p-0' onClick={() => { history.goBack() }} style={{ border: 'none' }} >
                            <span style={{ fontSize: "1.1rem" }} className="dds__icon--chevron-left dds__pb-0" />
                            <p className="dds__subtitle-3 dds__pl-1 dds__pt-0 dds__pr-3 dds__mb-0" style={{ display: 'inline', fontSize: '20px' }}>{' Back'}</p>
                        </button>}
                </div>

                <div id='leadertable' className='dds__mb-5'>
                    <ServerTable
                        apiArgs={[urlParams]}
                        apiConfig={getLeaderBoardConfig}
                        heading={headerLeaderBoard}
                        Icon={Icon}
                        columns={leaderTableColumns}
                        searchHeader="Search by Leader Name"
                        disableColumnFunctions
                    />
                </div>
            </div>
        </div >
    );
}

export default LeaderBoardPage;