import styled from "styled-components";

export const CarouselWrapper = styled.div`
	padding: 0;
	*:focus,
	input:focus ~ .dds__card-label-container {
		outline: 0.0625rem solid #000000 !important;
		outline-offset: 0.125rem !important;
		outline-offset: 0.125rem -webkit-focus-ring-color !important;
	}
	h6 {
		color: var(--blue-700)
	}
	p {
		text-align: left,
		color: var(--slate-700-dark),
		min-height: 10vh,
	}
`;
